import React, { useState } from "react";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!firstName.trim()) {
      errors.firstName = 'First name is required';
      valid = false;
    } else if (firstName.length < 2) {
      errors.firstName = 'First name must be at least 2 characters long';
      valid = false;
    }

    if (!lastName.trim()) {
      errors.lastName = 'Last name is required';
      valid = false;
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
      valid = false;
    }

    if (!phone.trim()) {
      errors.phone = 'Phone number is required';
      valid = false;
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = 'Phone number must be exactly 10 digits';
      valid = false;
    }

    if (!message.trim()) {
      errors.message = 'Message is required';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      // Handle successful form submission here
      alert('Form submitted successfully');
      // Clear form fields if needed
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setMessage('');
    }
  };
  return (
    <>
      {/* Breadcrumb Section Start */}
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Contact Us</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="fa-solid fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End */}

      {/* Contact Box Section Start */}
      <section className="contact-box-section">
        <div className="container-fluid-lg">
          <div className="row g-lg-5 g-3">
            <div className="col-lg-6">
              <div className="left-sidebar-box">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="contact-image">
                      <img
                        src="../assets/images/inner-page/contact-us.png"
                        className="img-fluid blur-up lazyloaded"
                        alt
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="contact-title">
                      <h3>Get In Touch</h3>
                    </div>
                    <div className="contact-detail">
                      <div className="row g-4">
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-phone" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Phone</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>(+91)9014 775 214</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-phone" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Phone</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>(+91)9014 775 214</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-envelope" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Email</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>
                                <a href="mailto: kingkisanghar@gmail.com">
                                  kingkisanghar@gmail.com
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6 p-2">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-location-dot" />
                            </div>
                            <div className="contact-detail-title ">
                              <h4 className="mt-1">KisanGhar</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>Hyderabad, Telangana 500038</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="contact-detail-box">
                            <div className="contact-icon">
                              <i className="fa-solid fa-building" />
                            </div>
                            <div className="contact-detail-title">
                              <h4>Bournemouth Office</h4>
                            </div>
                            <div className="contact-detail-contain">
                              <p>Visitación de la Encina 22</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="title d-xxl-none d-block">
                <h2>Contact Us</h2>
              </div>
              <div className="right-sidebar-box">
                <div className="row">
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label"
                      >
                        First Name
                      </label>
                      <div className="custom-input">
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="Enter First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <i className="fa-solid fa-user" />
                      </div>
                      {errors.firstName && (
                        <small style={{ color: "red" }}>
                          {errors.firstName}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Last Name
                      </label>
                      <div className="custom-input">
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <i className="fa-solid fa-user" />
                      </div>
                      {errors.lastName && (
                        <small style={{ color: "red" }}>
                          {errors.lastName}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label"
                      >
                        Email Address
                      </label>
                      <div className="custom-input">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <i className="fa-solid fa-envelope" />
                      </div>
                      {errors.email && (
                        <small style={{ color: "red" }}>{errors.email}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput3"
                        className="form-label"
                      >
                        Phone Number
                      </label>
                      <div className="custom-input">
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          placeholder="Enter Your Phone Number"
                          maxLength={10}
                          value={phone}
                          onChange={(e) =>
                            setPhone(e.target.value.slice(0, 10))
                          }
                        />
                        <i className="fa-solid fa-mobile-screen-button" />
                      </div>
                      {errors.phone && (
                        <small style={{ color: "red" }}>{errors.phone}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlTextarea"
                        className="form-label"
                      >
                        Message
                      </label>
                      <div className="custom-textarea">
                        <textarea
                          className="form-control"
                          id="message"
                          placeholder="Enter Your Message"
                          rows={6}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <i className="fa-solid fa-message" />
                      </div>
                      {errors.message && (
                        <small style={{ color: "red" }}>{errors.message}</small>
                      )}
                    </div>
                  </div>
                </div>
                <button className="btn btn-animation btn-md fw-bold ms-auto" onClick={handleSubmit}>
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Box Section End */}
    </>
  );
};

export default ContactUs;
