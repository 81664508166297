import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoryScreen = () => {
  const { vegCategory, nonvegCategory, supermartCategory, categoryTotal } =
    useSelector((state) => state.Category);

  const [showcat, setShowcat] = useState([]);

  useEffect(() => {
    const allcats = [...vegCategory, ...supermartCategory];
    // setShowcat(allcats.reverse());
    setShowcat(allcats);
  }, [supermartCategory, vegCategory]);

  return (
    <>
      {categoryTotal.length > 0 ? (
        <>
          <section className="category-section-2">
            <div className="container-fluid-lg">
              <div className="title">
                <h2 className="hotproducttext" style={{ textAlign: "center" }}>
                  Categories
                </h2>
              </div>
              <div className="row mt-2">
                <div className="categorydexrversion">
                  <div className="col-12 d-flex flex-wrap">
                    {showcat.map((category, index) => (
                      <div
                        key={index}
                        className="category-slider arrow-slidercol-xxl-2 col-lg-2 col-md-3 col-3  p-1"
                      >
                        <div>
                          <Link to={`/${category.slugUrl}`}>
                            <div className="shop-category-box border-0 wow fadeIn ">
                              <a className="circle-1">
                                <img
                                  src={category.mobileImage}
                                  className="img-fluid1 blur-up lazyload"
                                  alt="img"
                                  style={{ mixBlendMode: "multiply" }}
                                />
                              </a>
                            </div>
                            <div
                              style={{ textAlign: "center" }}
                              className="category-name"
                            >
                              <h6
                                style={{
                                  paddingTop: "6px",
                                  fontWeight: "700",
                                  fontSize: "11px",
                                  color: "#000",
                                }}
                              >
                                {category.name}
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div className="col-12 d-flex flex-wrap">
                  {supermartCategory.map((category, index) => (
                    <div
                      key={index}
                      className="category-slider arrow-slidercol-xxl-2 col-lg-2 col-md-3 col-6  p-1"
                    >
                      <div>
                        <Link to={`/${category.slugUrl}`}>
                          <div className="shop-category-box border-0 wow fadeIn ">
                            <a className="circle-1">
                              <img
                                src={category.mobileImage}
                                className="img-fluid blur-up lazyload"
                                alt="img"
                                style={{ height: "130px" }}
                              />
                            </a>
                            <div className="category-name">
                              <h6>{category.name}</h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div> */}

                <div className="col-12 d-flex flex-wrap">
                  {nonvegCategory.map((category, index) => (
                    <div
                      key={index}
                      className="category-slider arrow-slidercol-xxl-2 col-lg-2 col-md-3 col-3  p-1"
                    >
                      <div>
                        <Link to={`/${category.slugUrl}`}>
                          <div className="shop-category-box border-0 wow fadeIn ">
                            <a className="circle-1">
                              <img
                                src={category.mobileImage}
                                className="img-fluid1 blur-up lazyload"
                                alt="img"
                                style={{ mixBlendMode: "multiply" }}
                              />
                            </a>
                          </div>
                          <div
                            style={{ textAlign: "center" }}
                            className="category-name"
                          >
                            <h6
                              style={{
                                paddingTop: "6px",
                                fontWeight: "700",
                                fontSize: "11px",
                                color: "#000",
                              }}
                            >
                              {category.name}
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryScreen;
