import React, { useState, useEffect } from "react";
import { AiOutlineCaretRight } from "react-icons/ai";
import { BsHouseDoor } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  clientRegistation,
  getClientByMob,
  pageStatusChange,
} from "../redux/athentication/Athentication";
import { getOrderbyClId } from "../redux/order/OrderSlice";
import RegisterMobile from "./RegisterMobile";

const Registation = () => {
  const { mob } = useSelector((state) => state.Athentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(mob);

  const [email, setEmail] = useState("");

  const [name, setName] = useState("");

  const [errormassageEmail, setErrormassageEmail] = useState("");
  const [uniqueemail, setUniqueEmail] = useState("");

  const [regError, setRegError] = useState("");
  const [submiterror, setSubmiterror] = useState("red");

  const emailchange = (e) => {
    const value = e.target.value;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setErrormassageEmail("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        if (value != "") {
          setUniqueEmail(value);
        }
      } else {
        setErrormassageEmail("Enter correct Email format");
      }
    } else {
      setErrormassageEmail("Enter correct Email format");
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (name !== "" && uniqueemail !== "") {
      const formData = {
        Name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        Email: uniqueemail,
        Mobile: mob,
      };

      const ClientDetails = await dispatch(clientRegistation(formData));

      if (ClientDetails.payload.success) {
        setEmail(email);
        setName(name);
        setMobile(mobile);
        setRegError("Registation Successfully");
        setSubmiterror("green");
        dispatch(
          authActions.signin({
            // token: ClientDetails.token,
            ...ClientDetails.payload.client,
            isAuth: true,
          })
        );
        const client = ClientDetails.payload.client;
        dispatch(getOrderbyClId(client._id));
        navigate("/");
        dispatch(pageStatusChange(0));
      } else {
        setRegError("Registation Un-Successfully");
        setSubmiterror("red");
      }
    } else if (name === "") {
      setRegError("Name cannot be empty");
      setSubmiterror("red");
    } else if (email === "") {
      setRegError("Email cannot be empty");
      setSubmiterror("red");
    }
  };

  return (
    <>
      <section className="log-in-section section-b-space register_for_mobile_only">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="../assets/images/inner-page/sign-up.png"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="log-in-box">
                <div className="log-in-title">
                  <h3>Welcome To kingkisanghar </h3>
                  <h4>Create New Account</h4>
                </div>
                <div className="input-box">
                  <form className="row g-4">
                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          value={name}
                          required
                          onChange={(e) => setName(e.target.value)}
                          // placeholder="Full Name"
                          style={{ textTransform: "capitalize" }}
                        />
                        <label htmlFor="fullname">Full Name</label>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          // placeholder="Email Address"
                          required
                          value={email}
                          onChange={(e) => emailchange(e)}
                        />

                        <label htmlFor="email">Email Address</label>
                      </div>
                      <p style={{ color: "red" }} className="mt-2">
                        {errormassageEmail}
                      </p>
                    </div>

                    <div className="col-12">
                      <div className="form-floating theme-form-floating">
                        <input
                          type="text"
                          className="form-control"
                          value={mobile}
                          id="Mobile"
                          disabled
                          placeholder="Mobile"
                        />
                        <label htmlFor="text">number</label>
                      </div>
                    </div>
                    <p style={{ color: submiterror }} className="mt-2">
                      {regError}
                    </p>

                    <div className="col-12">
                      <button
                        className="btn btn-animation w-100"
                        onClick={onSubmitHandler}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6 col-lg-6" />
          </div>
        </div>
      </section>
      <section>
        <RegisterMobile />
      </section>

    </>

  );
};

export default Registation;
