import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../../../redux/athentication/Athentication";
import {
  gerenatenewTokrnId,
  getOrderbyClId,
  updateOrder,
} from "../../../redux/order/OrderSlice";
import { toast } from "react-toastify";
import axios from "axios";

export const OrderListMobile = () => {
  const { clientOrder } = useSelector((state) => state.Order);
  console.log(clientOrder.length, "clientOrder.length");

  const { loginData, clientid } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const orderCancelClick = async (e, selectOrder) => {
    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
    } else {
      var OrderprocessDate = {};
    }

    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: selectOrder.OrderprocessDate.OutfordeliveryDate,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: date,
      };
    } else {
      var OrderprocessDate = {};
    }

    const formData = {
      Status: 0,
      StatusText: "Order Cancelled",
      orderid: selectOrder._id,
      remark: {
        OrderCancelRemark: "Your delivery is cancelled",
      },
      OrderprocessDate: OrderprocessDate,
    };
    const data = await dispatch(updateOrder(formData));

    const getClientForm = {
      Mobile: selectOrder.Mobile,
    };

    const getOrderClient = await dispatch(getClientByMob(getClientForm));

    let getclient = getOrderClient.payload.client;
    if (selectOrder.CurrentStatus === "Not Paid") {
      if (selectOrder.wallet > 0) {
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          claintid: selectOrder.ClientId,
        };
        dispatch(clientUpdate(formDta));

        const walletForm = {
          ClientId: getclient._id,
          previousAmount: getclient.WalletBalance,
          availableAmount:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          transactionAmount: Number(selectOrder.wallet),
          orderId: selectOrder._id,
          Type: "Wallet Credit on Order Cancel",
          ClientName: getclient.Name,
          Mobile: getclient.Mobile,
        };
        await dispatch(walletTransitionPost(walletForm));
      }
    } else if (selectOrder.CurrentStatus === "Paid") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) + Number(selectOrder.TotalAmount),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) + Number(selectOrder.wallet),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    } else if (selectOrder.CurrentStatus === "Partial Payment") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Partial Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));

      dispatch(getOrderbyClId(clientid));
    }

    const sendOptthowwp = async () => {
      const name = selectOrder.ClientName;
      const orderId = String(selectOrder._id.slice(18).toUpperCase());
      const amount = String(selectOrder.GrandTotal);
      const mobile = selectOrder.Mobile;
      let data = JSON.stringify({
        countryCode: "+91",
        phoneNumber: mobile,
        callbackData: "some text here",
        type: "Template",
        template: {
          name: "order_cancelled",
          languageCode: "en",
          bodyValues: [name, orderId, amount],
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.interakt.ai/v1/public/message/",
        headers: {
          Authorization:
            "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    sendOptthowwp();
  };
  return (
    <>
      <section className="product-section">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp">
              <div className="row g-4">
                <div className="col-12">
                  <div className="product-section-box">
                    <h2>ORDERS</h2>

                    <div className="tab-content custom-tab" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="dashboard-order">
                          {clientOrder.length > 0 ? (
                            <>
                              <div className="order-contain">
                                {clientOrder
                                  // .slice()
                                  // .reverse()
                                  .map((order, index) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                      className="order-box dashboard-bg-box"
                                      key={index}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                        className="order-container"
                                      >
                                        <Link
                                          to={`/ordertracking/${order._id}`}
                                        >
                                          <div className="order-icon">
                                            {/* <i data-feather="box" /> */}
                                            <img
                                              src="../assets/images/cart.png"
                                              alt="img"
                                              width={100}
                                            />
                                          </div>
                                        </Link>
                                        <Link
                                          to={`/ordertracking/${order._id}`}
                                        >
                                          <div className="order-detail">
                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Net Payable:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                ₹{order.GrandTotal}
                                              </h5>
                                            </h4>
                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Order Status:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.StatusText}
                                              </h5>
                                            </h4>

                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Payment Type:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.PaymentMode}
                                              </h5>
                                            </h4>
                                          </div>
                                          <div className="order-detail">
                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Payment Status:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.CurrentStatus}
                                              </h5>
                                            </h4>

                                          

                                            <h4>
                                              <h5 className="success-bg me-2  text-success">
                                                Transaction Status:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {order.PaymentStatus}
                                              </h5>
                                            </h4>

                                            <h4>
                                              <h5 className="success-bg me-2 text-success">
                                                Order Date:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {moment(order.createdAt).format(
                                                  "MMM DD YY"
                                                )}
                                              </h5>
                                            </h4>
                                            <h4>
                                              <h5 className="success-bg me-2 text-success">
                                                Expected Delivery Date:
                                              </h5>
                                              <h5 style={{ color: "red" }}>
                                                {moment(
                                                  order.ExpectedDelDate
                                                ).format("MMM DD YY")}
                                              </h5>
                                            </h4>
                                          </div>
                                        </Link>
                                        <div className="d-flex d-flex flex-column">
                                          <div className="addtocart_btn my-1">
                                            <Link
                                              to={`/ordertracking/${order._id}`}
                                            >
                                              <button
                                                className="btn buy-button"
                                                style={{
                                                  background: "#00ab66",
                                                  color: "#fff",
                                                }}
                                              >
                                                View order
                                              </button>
                                            </Link>
                                          </div>
                                          {order.Status === 1 ? (
                                            <>
                                              <div className="addtocart_btn my-1">
                                                <button
                                                  className="btn buy-button"
                                                  style={{
                                                    background: "#00ab66",
                                                    color: "#fff",
                                                  }}
                                                  onClick={(e) =>
                                                    orderCancelClick(e, order)
                                                  }
                                                >
                                                  Order Cancel
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                         

                                        
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="order-contain">
                                <section className="section-404 section-lg-space">
                                  <div className="container-fluid-lg">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="image-404">
                                          <img
                                            src="../assets/images/inner-page/emptycart.png"
                                            className="img-fluid blur-up lazyload"
                                            alt
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="contain-404">
                                          <Link to="/">
                                            <button className="btn btn-md text-white theme-bg-color mt-4 mx-auto">
                                              Back To Home Screen
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
