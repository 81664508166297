import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import "swiper/css";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";

const MunchiesSubcat = () => {
  const [subCategory, setSubCategory] = useState([]);
  const [category, setCategory] = useState("");

  useEffect(() => {
    const categoryfetchData = async () => {
      const url = `${Baseurl}/api/v1/category/categorybyid/66cdc48f8c198c3f7c3c3249`;
      const category = await axios.get(url);

      try {
        if (category.data.success === true) {
          const cat = category.data.category;

          setCategory(cat);
        }
      } catch (error) { }
    };
    categoryfetchData();
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/subcategory/subcategoryid/66cdc48f8c198c3f7c3c3249`;
      const fetchSubcat = await axios.get(url);

      try {
        if (fetchSubcat.data.success === true) {
          const subcat = fetchSubcat.data.subcategory.filter(
            (data) => data.status === true
          );

          setSubCategory(subcat);
        }
      } catch (error) { }
    };
    fetchData();
  }, []);



  return (
    <>
      {subCategory.length > 0 ? (
        <section className="category-section-3">
          <div className="container">
            <div className="title">
              <h2 className="hotproducttext" style={{ textTransform: "capitalize", textAlign: "center" }}>
                {category.name} Sub-Categories
              </h2>
            </div>
            <div className="row mt-2">
              <div className="col-12 d-flex flex-wrap">
                {subCategory &&
                  subCategory.map((subcat, index) => (
                    <div className="category-slider-1 flex-gap arrow-slider wow fadeInUp  col-xxl-2 col-lg-2 col-md-3 col-4">
                      <div className="  category-box-list ">
                        <a className="category-name">
                          <h4 className="subcategorytext" style={{ fontWeight: "700", color: "#000" }}>{subcat.name}</h4>
                          {/* <h6>25 items</h6> */}
                        </a>
                        <div className="category-box-view">
                          <Link to={`/${category.slugUrl}/${subcat.slugUrl}`}>
                            <a>
                              <img
                                src={subcat.desktopImage}
                                className="img-fluid blur-up lazyload"
                                alt="img"
                                height={120}
                                style={{
                                  // maxHeight: "120px",
                                  // minHeight: "100px",
                                  mixBlendMode: "multiply"
                                }}
                              />
                            </a>
                          </Link>
                          <Link to={`/${category.slugUrl}/${subcat.slugUrl}`}>
                            <button className="btn shop-button">
                              <span>Shop Now</span>
                              <i className="fas fa-angle-right" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default MunchiesSubcat;
