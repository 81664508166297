import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import Header from "./cart/Header";
import CartProducts from "./cart/CartProducts";
import CartCheckOut from "./cart/CartCheckOut";
import EmptyCart from "./cart/EmptyCart";
import { FiSearch, FiUser } from "react-icons/fi";
import { BsTelephone, BsCart3 } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosNotifications } from "react-icons/io";
import { BsArrowRepeat } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { LuShoppingBag } from "react-icons/lu";

import { useDispatch, useSelector } from "react-redux";
import CartDemo from "./cart/CartDemo";
import { getCartTotal, removefromCart } from "../redux/cart/CartSlice";
import { pageStatusChange } from "../redux/athentication/Athentication";
import FillCart from "./cart/FillCart";

const Cart = () => {
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { categoryTotal } = useSelector((state) => state.Category);
  // const { groceresBrand } = useSelector((state) => state.Brand);

  const { isAuth, name, loginData, showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );

  const [search, setSerch] = useState("");
  const [subCatSerchList, setSubCatSerchList] = useState("");
  const [catSerchList, setCatSerchList] = useState([]);
  const [prodSerchList, setProdSerchList] = useState([]);
  const [brandprodSerchList, setBrandProdSerchList] = useState("");
  const [showResults, setShowResults] = useState(false);

  const [fetchAllProducts, setFetchAllProducts] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [address, setAddress] = useState("Hyderabad , Telangana");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const placeholderArray = [
    "I'm searching for Organic Vegetable..",
    "I'm searching for Organic Fruits..",
    "I'm searching for Dry Fruits...",
    "I'm searching for Chocolates desserts...",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholderArray.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [placeholderArray.length]);

  // current location google api
  const fetchAddress = (latitude, longitude) => {
    const apiKey = "AIzaSyAkOSe3Ns4VZ9MUqztuZj49GrUQ3mMqMag";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          // console.log(
          //   data.results[0],
          //   "data.results[0].formatted_address345689"
          // );
          // console.log(
          //   `${data.results[0].address_components[4].long_name},${data.results[0].address_components[4].short_name}`,
          //   "asdfg"
          // );

          setAddress(`${data.results[0].address_components[4].long_name}`);
        } else {
          setAddress("Address not found");
        }
      })
      .catch((error) => console.error(error));
  };
  // current location google api
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position.coords, "position.coords");
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            fetchAddress(latitude, longitude);
          },
          (error) => {
            console.error("Error Code = " + error.code + " - " + error.message);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(pageStatusChange(0));
  }, [CartItems, dispatch]);

  const removefromcarts = (cartItem) => {
    dispatch(removefromCart(cartItem));
  };

  const searchchange = (e) => {
    const result = e.target.value;
    setSerch(result);

    if (result.length >= 2) {
      const filtersubcatbyserch = subCategoryTotal
        .filter((subcat) =>
          subcat.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 2); //Only the first 2 matching results are selected

      const filtercatdbyserch = categoryTotal
        .filter((cat) => cat.name.toLowerCase().match(result.toLowerCase()))
        .slice(0, 2);
      const filterProductbyserch = ProductTotal.filter((product) =>
        product.ItemName.toLowerCase().match(result.toLowerCase())
      ).slice(0, 2);
      setSubCatSerchList(filtersubcatbyserch);
      setCatSerchList(filtercatdbyserch);
      setProdSerchList(filterProductbyserch);
      // setBrandProdSerchList(filterBrandbyserch);
      setShowResults(true);
    }
  };

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };
  const handleSelection = (selectedItem) => {
    setSerch(selectedItem);
    setShowResults(false);
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };

  useEffect(() => {
    dispatch(getCartTotal());
  }, []);

  return (
    <>
      <header className="pb-md-3 hedcontainermain cartheddexversion">
        <div className="topheaderbar">
          <marquee className="scrolling-text" direction="left">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="martext">
                Exciting News! 🎉 We're launching our brand-new website on 18th
                October! Stay tuned for exclusive offers, updates, and a whole
                new experience.
              </div>
            </div>
          </marquee>
        </div>
        {/* =============header top start===================== */}
        <div className="top-nav top-header sticky-header hedcontainermain">
          <div className="container-fluid-lg hedcontainermain1">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">
                  <a className="web-logo nav-logo">
                    <Link to="/">
                      <img
                        // src="../assets/images/kisanlogo2.jpg"
                        src="../assets/images/kisanlogo20.png"
                        className="img-fluid blur-up lazyload"
                        alt="img"
                        style={{
                          filter: "drop-shadow(1.5px 1px 0.2px #fff)",
                        }}
                      />
                    </Link>
                  </a>

                  {/* ============middle start============== */}
                  <div className="middle-box dextop">
                    {isAuth === true ? (
                      <>
                        <Link to="/account">
                          <div
                            className="location-box"
                            // style={{
                            //   backgroundColor: "#01cb88",
                            //   color: "#fff",
                            //   borderColor: "#01cb88",
                            //   borderWidth: ".3px",
                            //   height: "50px",
                            // }}
                          >
                            <button
                              className="btn location-button"
                              data-bs-toggle="modal"
                              data-bs-target="#locationModal"
                            >
                              <span
                              // className="location-arrow"
                              // style={{
                              //   backgroundColor: "#01cb88",
                              //   color: "#fff",
                              //   borderColor: "#01cb88",
                              //   borderWidth: ".3px",
                              //   boxShadow:
                              //     "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                              // }}
                              >
                                <MdLocationOn
                                  style={{ color: "#f39636", fontSize: "20px" }}
                                />
                              </span>
                              <span
                                className="locat-name"
                                style={{
                                  color: "#fff",
                                  // fontFamily: "Poppins",
                                  paddingLeft: "4px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                              >
                                {address}
                              </span>
                              {/* <i className="fa-solid fa-angle-down" /> */}
                            </button>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/login">
                          <div
                            className="location-box"
                            // style={{
                            //   backgroundColor: "#01cb88",
                            //   color: "#fff",
                            //   borderColor: "#01cb88",
                            //   borderWidth: ".3px",
                            //   height: "50px",
                            // }}
                          >
                            <button
                              className="btn location-button"
                              data-bs-toggle="modal"
                              data-bs-target="#locationModal"
                            >
                              <span
                              // className="location-arrow"
                              // style={{
                              //   backgroundColor: "#01cb88",
                              //   color: "#fff",
                              //   borderColor: "#01cb88",
                              //   borderWidth: ".3px",
                              //   boxShadow:
                              //     "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                              // }}
                              >
                                <MdLocationOn
                                  style={{ color: "#f39636", fontSize: "20px" }}
                                />
                              </span>
                              <span
                                className="locat-name"
                                style={{
                                  color: "#fff",
                                  // fontFamily: "Poppins",
                                  paddingLeft: "4px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                              >
                                {address}
                              </span>
                              {/* <i className="fa-solid fa-angle-down" /> */}
                            </button>
                          </div>
                        </Link>
                      </>
                    )}

                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            style={{
                              backgroundColor: "#01cb88",
                              color: "#fff",
                              borderColor: "#01cb88",
                              borderWidth: ".3px",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                            className="form-control"
                            placeholder={placeholderArray[currentIndex]}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            onChange={searchchange}
                          />

                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                            style={{
                              backgroundColor: "#01cb88",
                              borderColor: "#01cb88",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                          >
                            <FiSearch style={{ fontSize: "20px" }} />
                          </button>
                        </div>
                      </form>

                      {showResults && prodSerchList.length > 0 && (
                        <>
                          <div className="serchsugist">
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() =>
                                        handleSelection(data.Category)
                                      }
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          In Product
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          {" "}
                                          {data.name} in Brand
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* ============middle end============== */}

                  <div className="rightside-box">
                    <div className="search-full">
                      <div className="input-group">
                        <span className="input-group-text">
                          <FiSearch />
                        </span>
                        <input
                          type="text"
                          className="form-control search-type"
                          placeholder="Search here.."
                        />
                        <span className="input-group-text close-search">
                          <i data-feather="x" className="font-light" />
                        </span>
                      </div>
                    </div>
                    <ul className="right-side-menu">
                      <li className="right-side">
                        <div className="delivery-login-box">
                          <div className="delivery-icon">
                            <div className="search-box">
                              <i data-feather="search" />
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="right-side">
                        <div className="onhover-dropdown header-badge">
                          <Link to="/cart">
                            {CartItems.length === 0 ? (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3
                                  style={{
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3
                                  style={{
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                />
                                <span
                                  className="position-absolute top-0 start-100 translate-middle badge"
                                  style={{
                                    backgroundColor: "#f39636",
                                    borderRadius: "50px",
                                  }}
                                >
                                  {CartItems.length}
                                </span>
                              </button>
                            )}
                          </Link>
                          {CartItems.length === 0 ? (
                            <></>
                          ) : (
                            <div className="onhover-div">
                              <ul className="cart-list">
                                {CartItems.map((data, index) => (
                                  <li
                                    className="product-box-contain"
                                    key={index}
                                  >
                                    <div className="drop-cart">
                                      <a className="drop-image">
                                        <img
                                          src={data.ImgUrl}
                                          className="blur-up lazyload"
                                          alt="img"
                                        />
                                      </a>
                                      <div className="drop-contain">
                                        <a>
                                          <h5>{data.ItemName}</h5>
                                        </a>
                                        <h6>
                                          <span>{data.Qty} x</span> ₹
                                          {data.Price}
                                        </h6>
                                        <button
                                          className="close-button close_button"
                                          onClick={() => removefromcarts(data)}
                                        >
                                          <GrFormClose />
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div className="price-box">
                                <h5>Total :</h5>
                                <h4 className="theme-color fw-bold">
                                  ₹{cartTotalAmount}
                                </h4>
                              </div>
                              <div className="button-group">
                                <Link to="/cart">
                                  <button className="btn btn-sm cart-button">
                                    View Cart
                                  </button>
                                </Link>

                                {isAuth ? (
                                  <>
                                    <Link to="/checkout">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="/login">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                      <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          {isAuth ? (
                            <Link to="/account">
                              <div
                                className="delivery-icon"
                                style={{ color: "#fff", fontFamily: "Poppins" }}
                              >
                                <FaRegUserCircle
                                  style={{
                                    fontSize: "22px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                  className="mx-2"
                                />{" "}
                                {name}
                              </div>
                            </Link>
                          ) : (
                            <Link to="/login">
                              <div className="delivery-icon loginserch">
                                <FaRegUserCircle
                                  style={{
                                    fontSize: "22px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                  className="mx-2"
                                />
                              </div>
                            </Link>
                          )}
                        </div>
                      </li>
                      <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          <Link>
                            <div
                              className="delivery-icon"
                              style={{ color: "#fff", fontFamily: "Poppins" }}
                            >
                              <LuShoppingBag
                                style={{
                                  fontSize: "22px",
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                }}
                                className="mx-2"
                              />
                            </div>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {showMassage === true ? (
                  <>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "5vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0e947a",
                        borderRadius: "3px",
                      }}
                    >
                      <marquee scrolldelay="100">
                        <h6
                          style={{
                            fontSize: "1.1rem",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {massageText}
                        </h6>
                      </marquee>
                    </div> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <div style={{ width: "92%" }}>
              <div style={{ padding: "6px" }} className="locationdeskversion">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {isAuth === true ? (
                    <>
                      <Link to="/account">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaLocationDot
                            style={{
                              fontSize: "15px",
                              color: "#00ab66",
                              fontWeight: "600",
                            }}
                          />
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              paddingLeft: "5px",
                              fontSize: "15px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              textTransform: "capitalize",
                            }}
                          >
                            {address}
                          </h6>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="/login">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaLocationDot
                            style={{
                              fontSize: "15px",
                              color: "#00ab66",
                              fontWeight: "600",
                            }}
                          />
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              paddingLeft: "5px",
                              fontSize: "15px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              textTransform: "capitalize",
                            }}
                          >
                            {address}
                          </h6>
                        </div>
                      </Link>
                    </>
                  )}

                  <div
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "20px",
                      backgroundColor: "#eff9f8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IoIosNotifications style={{ fontSize: "21px" }} />
                  </div>
                </div>
                <div className="navbar-top ">
                  <div className="middle-box mobile mt-2 ">
                    {/* <div className="location-box">
                      <button
                        className="btn location-button"
                        data-bs-toggle="modal"
                        data-bs-target="#locationModal"
                      >
                        <span className="location-arrow">
                          <TfiLocationPin />
                        </span>
                        <span className="locat-name">Your Location</span>
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </div> */}
                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            style={{
                              borderTopLeftRadius: "7px",
                              borderBottomLeftRadius: "7px",
                            }}
                            className="form-control"
                            placeholder={placeholderArray[currentIndex]}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            ref={ref}
                            onChange={searchchange}
                          />
                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                          >
                            <FiSearch />
                          </button>
                        </div>
                      </form>
                      {showResults && prodSerchList.length > 0 && (
                        <>
                          <div className="serchsugist ">
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() =>
                                        handleSelection(data.Category)
                                      }
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          In Product
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          {" "}
                                          {data.name} in Brand
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============header top end===================== */}
      </header>

      {CartItems.length === 0 ? (
        <>
          <EmptyCart />{" "}
        </>
      ) : (
        <>
          {/* <Header /> */}
          <section
            className="cart-section section-b-space cartfootercontainer"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="container-fluid-lg">
              <div className="row g-sm-5 g-3 desktopversioncart">
                <CartProducts />
                <CartCheckOut />
              </div>
              <div className="row g-sm-5 g-3 mobileversioncart">
                <FillCart />
              </div>
            </div>
          </section>
        </>
      )}
      <div className="mobile-menu d-md-none d-block mobile-cart containerrrrrrr_none_in_mobileee">
        <ul>
          <li>
            <Link to="/">
              <button
                className="btn btn-light shopping-button text-dark returnsopppingcon"
                style={{ fontSize: "13px" }}
              >
                Return To Shopping
              </button>
            </Link>
          </li>
          {isAuth ? (
            <>
              <li>
                <Link to="/checkout">
                  <button className="btn btn-animation proceed-btn fw-bold">
                    Process To Checkout
                  </button>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login">
                  <button className="btn btn-animation proceed-btn fw-bold">
                    Process To Checkout
                  </button>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Cart;
