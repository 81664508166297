import React from "react";
import ProductSerch from "./searchpages/ProductSerch";

const HomeProductSerch = () => {
  return (
    <>
      <ProductSerch />
    </>
  );
};

export default HomeProductSerch;
