import React from "react";
import CategorySearch from "./searchpages/CategorySearch";

const HomeCategorySearch = () => {
  return (
    <>
      <CategorySearch />
    </>
  );
};

export default HomeCategorySearch;
