import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  FeatureInWeekTotal: [],
  // VegFeatureInWeekSlider: localStorage.getItem("FeatureInWeekTotal")
  //   ? JSON.parse(localStorage.getItem("FeatureInWeekTotal")).filter(
  //       (ban) => ban.superCategory === "Vegetables & fruits"
  //     )
  //   : [],
  // nonVegFeatureInWeekSlider: localStorage.getItem("FeatureInWeekTotal")
  //   ? JSON.parse(localStorage.getItem("FeatureInWeekTotal")).filter(
  //       (ban) => ban.superCategory === "Non-veg"
  //     )
  //   : [],
  // supermartFeatureInWeekSlider: localStorage.getItem("FeatureInWeekTotal")
  //   ? JSON.parse(localStorage.getItem("FeatureInWeekTotal")).filter(
  //       (ban) => ban.superCategory === "Supermart"
  //     )
  //   : [],

  isFeatureInWeekLoading: false,
};

export const getFeatureInWeekBanners = createAsyncThunk(
  "FeatureInWeek/getFeatureInWeekBanners",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/featuredinweek/all`;
      const resp = await axios.get(url);
      return resp.data.featuredInWeek;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const featureInWeekAllSlice = createSlice({
  name: "FeatureInWeek",
  initialState,
  reducers: {
    getFeatureInWeekset(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeatureInWeekBanners.pending, (state) => {
        state.isFeatureInWeekLoading = true;
      })
      .addCase(getFeatureInWeekBanners.fulfilled, (state, action) => {
        state.FeatureInWeekTotal = action.payload;
        state.isFeatureInWeekLoading = false;
      })
      .addCase(getFeatureInWeekBanners.rejected, (state) => {
        state.isFeatureInWeekLoading = false; 
      });
  },
});

export const { getFeatureInWeekset } = featureInWeekAllSlice.actions;
export default featureInWeekAllSlice.reducer;
