import React from "react";
import RecomendedProducts from "./Homeslider/RecomendedProducts";
import HotProducts from "./Homeslider/HotProducts";
import OffersProduct from "./Homeslider/OffersProduct";

const HomeSliderProducts = () => {
  return (
    <>
      <RecomendedProducts />
      <HotProducts />
      <OffersProduct />
    </>
  );
};

export default HomeSliderProducts;
