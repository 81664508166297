import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";


const SubProductSectionMobile = (props) => {
  const { ProductTotal, CartItems, Cartloading, ProductLoading } = useSelector(
    (state) => state.Cart
  );
  const dispatch = useDispatch();

  const [currentSubCatProducts, setCurrentSubCatProducts] = useState([]);
  console.log(currentSubCatProducts, "currentSubCatProducts");

  const [fetchloading, setFetchloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");
  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };
  useEffect(() => {
    const prod = props.curShowProducts.filter(
      (product) => product.PackSizes.length !== 0
    );
    setCurrentSubCatProducts(prod);
  }, [props.curShowProducts]);

  const addtocartPress = async (e, subcatProduct, itemIndex, packIndex) => {
    try {
      let products = [...currentSubCatProducts];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity + 1;
      setCurrentSubCatProducts([...products]);
    } catch (error) { }

    const formData = {
      ProductId: subcatProduct._id,
      ProductName: subcatProduct.ItemName,
      CatName: subcatProduct.Category,
      CatId: subcatProduct.CatId,
      SubCat: subcatProduct.SubCat,
      SubCatId: subcatProduct.SubCatId,
      Brand: subcatProduct.Brand,
      ItemName: subcatProduct.ItemName,
      PackSize: subcatProduct.PackSizes[packIndex].PackSize,
      Description: subcatProduct.Description,
      ImgUrl: subcatProduct.PackSizes[packIndex].ImgUrlMbl,
      Price: subcatProduct.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: subcatProduct.PackSizes[packIndex].SellingPrice * 1,
      Mrp: subcatProduct.PackSizes[packIndex].Mrp,
      TotalPrice: subcatProduct.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: subcatProduct.PackSizes[packIndex].Mrp * 1,
      Discount:
        subcatProduct.PackSizes[packIndex].Mrp * 1 -
        subcatProduct.PackSizes[packIndex].SellingPrice * 1,
      Cashback: subcatProduct.Cashback,
      SellingPrice: subcatProduct.PackSizes[packIndex].SellingPrice,
      GstSellPrc: subcatProduct.PackSizes[packIndex].GstSellPrc,
      CostPrc: subcatProduct.PackSizes[packIndex].CostPrc,
      GstCost: subcatProduct.PackSizes[packIndex].GstCost,
      packsizeId: subcatProduct.PackSizes[packIndex]._id,
      maximumQuantity: subcatProduct.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = [...currentSubCatProducts];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity - 1;

      setCurrentSubCatProducts([...products]);
    } catch (error) { }

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  const filterbyAtoZorder = () => {
    const filterValues = [...currentSubCatProducts].sort((a, b) =>
      a.ItemName > b.ItemName ? 1 : -1
    );

    setCurrentSubCatProducts(filterValues);
  };
  const filterbyZtoAorder = () => {
    const filterValues = [...currentSubCatProducts].sort((a, b) =>
      a.ItemName > b.ItemName ? -1 : 1
    );
    setCurrentSubCatProducts(filterValues);
  };
  return (
    <div>
      <div className="to_flex_cat_sub">
        {currentSubCatProducts &&
          currentSubCatProducts.map((product, itemIndex) => (
            <>
              <div key={itemIndex}>
                {product.PackSizes.map((pack, packIndex) =>
                  pack.Pri === true ? (
                    <>
                      <div className="card_contaner_for_mobile">
                        <div className="percentageeee">
                          <span className="discounttttttt">{pack.Discount}% off</span>
                        </div>
                        <Link to={`/product-info/${product.Url}`}>
                          <div className="img_connnn">
                            <img
                              //   src="../assets/images/freshveg.jpg"
                              src={pack.ImgUrlDesk}
                              alt="error"
                              className="img_con_img"
                            />
                          </div>
                        </Link>
                        <div className="drown_pro_det">
                          <div>
                            <span className="sub_prooo_name">
                              {product.ItemName.length > 15
                                ? product.ItemName.slice(0, 15) + "..."
                                : product.ItemName}
                            </span>
                          </div>
                          <div className="dis_prices">
                            <span className="bold_priii">
                              ₹{pack.SellingPrice}
                            </span>
                            <span className="sellingggggg_price">
                              {pack.Mrp === pack.SellingPrice ? (
                                <></>
                              ) : (
                                <>₹{pack.Mrp}</>
                              )}
                            </span>
                          </div>
                          <div className="button_spacesssss">
                            <div style={{display:'flex'}}>

                              {
                                product.Multi === true ? (<>
                                  <span className="grmasss_con">
                                    {pack.PackSize}
                                  </span>
                                  <span>
                                    <IoMdArrowDropdown />
                                  </span>
                                </>) : (<>


                                  <span className="grmasss_con">
                                    {pack.PackSize}
                                  </span>
                                </>)
                              }


                            </div>
                            <div>
                              {pack.OutOfStack === true ? (
                                <>
                                  <div>
                                    <button className="add_btn_incre_mo_stack">
                                      Out Of Stack
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {product.Multi === true ? (
                                    <>
                                      <div>
                                        <button
                                          className="add_btn_incre_mo"
                                          onClick={() =>
                                            optionsProductset(
                                              product,
                                              itemIndex
                                            )
                                          }
                                        >
                                          <FaPlus />
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {currentSubCatProducts[itemIndex]
                                        .cartPackquentity[packIndex]
                                        .CartQuantity > 0 ? (
                                        <>
                                          <div>
                                            <div className="add_btn_incre_mo_increme">
                                              <button

                                                className="plusssss_btn"
                                                onClick={(e) =>
                                                  decrementtocartPress(
                                                    e,
                                                    product,
                                                    itemIndex,
                                                    packIndex
                                                  )
                                                }
                                              >
                                                <FaMinus />
                                              </button>
                                              <span>{
                                                currentSubCatProducts[itemIndex]
                                                  .cartPackquentity[packIndex]
                                                  .CartQuantity
                                              }</span>

                                              {11 >=
                                                currentSubCatProducts[itemIndex]
                                                  .cartPackquentity[packIndex]
                                                  .CartQuantity ? (
                                                <>
                                                  <button

                                                    className="minussss_btn"
                                                    onClick={(e) =>
                                                      addtocartPress(
                                                        e,
                                                        product,
                                                        itemIndex,
                                                        packIndex
                                                      )
                                                    }
                                                  >
                                                    <FaPlus />
                                                  </button>
                                                </>
                                              ) :
                                                (<>
                                                  <button className="minussss_btn">
                                                    <FaPlus />
                                                  </button>
                                                </>)}

                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div>
                                            <button
                                              className="add_btn_incre_mo"
                                              onClick={(e) =>
                                                addtocartPress(
                                                  e,
                                                  product,
                                                  itemIndex,
                                                  packIndex
                                                )
                                              }
                                            >
                                              <FaPlus />
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div key={packIndex}></div>
                    </>
                  )
                )}
              </div >
            </>
          ))}
      </div>
      <Modal
        size="md"
        // centered
        isOpen={visible}
        toggle={() => setVisible(!visible)}
        modalTransition={{ timeout: 500 }}
        className="slide-up-modallll"
      >
        <ModalBody>
          <h3>{currentProduct.ItemName}</h3>
          <h5>{currentProduct.Brand}</h5>
          <div className="col-12 mt-3 ">
            {currentProduct &&
              currentProduct.PackSizes.map((packsize, packIndex) => (
                <div
                  className="col-12 d-flex justify-content-between my-2"
                  key={packsize._id}
                >
                  <div className="col-2">
                    <img
                      src={packsize.ImgUrlDesk}
                      className="img-fluid blur-up lazyload"
                      alt="image"
                      width={200}
                    />
                  </div>
                  <h6
                    className="col-1 d-flex justify-content-center align-items-center "
                    style={{
                      color: "#00ab66",
                      fontSize: "14px",
                      marginLeft: "2vh",
                    }}
                  >
                    {packsize.PackSize}
                  </h6>
                  <h6
                    className="col-3 d-flex justify-content-center align-items-center "
                    style={{
                      color: "#00ab66",
                      fontSize: "14px",
                      marginLeft: "2vh",
                    }}
                  >
                    ₹{packsize.SellingPrice}
                    {packsize.Mrp === packsize.SellingPrice ? (
                      <> </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            textDecorationLine: "line-through",
                            marginLeft: "0.5vh",
                          }}
                        >
                          ₹{packsize.Mrp}
                        </span>
                      </>
                    )}
                  </h6>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    {packsize.OutOfStack === true ? (
                      <>
                        {" "}
                        <Button
                          variant="contained"
                          style={{
                            color: "#fff",
                            background: "#00ab66",
                            // fontSize:"15px"
                          }}
                          className="mx-1"
                        >
                          out of stock
                        </Button>
                      </>
                    ) : (
                      <>
                        {currentProduct.cartPackquentity[packIndex]
                          .CartQuantity > 0 ? (
                          <>
                            <div className="addtocart_btn">
                              <div className="qty-box cart_qty open">
                                <div className="input-group">
                                  <button
                                    type="button"
                                    className="btn qty-left-minus"
                                    data-type="minus"
                                    data-field
                                    style={{
                                      backgroundColor: "#ebb9f8",
                                    }}
                                    onClick={(e) =>
                                      decrementtocartPress(
                                        e,
                                        currentProduct,
                                        curritemIndex,
                                        packIndex
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>

                                  <input
                                    className="form-control input-number qty-input mx-2"
                                    type="text"
                                    name="quantity"
                                    defaultValue={0}
                                    value={
                                      currentProduct.cartPackquentity[packIndex]
                                        .CartQuantity
                                    }
                                    readonly="readonly"
                                  />

                                  {11 ===
                                    currentProduct.cartPackquentity[packIndex]
                                      .CartQuantity ? (
                                    <>
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn qty-left-plus"
                                        data-type="plus"
                                        data-field
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn qty-left-plus"
                                        data-type="plus"
                                        data-field
                                        onClick={(e) =>
                                          addtocartPress(
                                            e,
                                            currentProduct,
                                            curritemIndex,
                                            packIndex
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              style={{
                                color: "#fff",
                                background: "#00ab66",
                              }}
                              className="mx-1"
                              onClick={(e) =>
                                addtocartPress(
                                  e,
                                  currentProduct,
                                  curritemIndex,
                                  packIndex
                                )
                              }
                            >
                              Add
                              <span className="mx-1">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SubProductSectionMobile;
