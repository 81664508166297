import { Footer } from 'antd/es/layout/layout'
import React from 'react'
import { BiCategoryAlt } from 'react-icons/bi'
import { BsArrowRepeat, BsCart3 } from 'react-icons/bs'
import { FaArrowLeft, FaRegUserCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
const Notification = () => {
    const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
        (state) => state.Cart
    );

    const { isAuth, name, loginData, showMassage, massageText } = useSelector(
        (state) => state.Athentication
    );
    return (
        <div className='mobile_notification'>
            <div className='headerrrr_notificatin'>
                <Link to="/">
                    <div>
                        <FaArrowLeft style={{ color: "#000" }} />
                    </div>
                </Link>
            </div>

            <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className='center_notifi'>
                    <div>
                        <img src="../assets/images/notification/emptynotification-img.png" className='img_noti' alt="Empty Notification" />

                    </div>
                    <div className='sub_alert'>
                        <div>
                            <h2>No alerts to show</h2>
                        </div>
                        <div>
                            <p className='existing'>You don't have any existing alerts!</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="mobile-menu d-md-none d-block mobile-cart">
                <ul>
                    <li className="active">
                        <Link to="/">
                            <a>
                                {/* <IoHomeOutline className=" icli" /> */}
                                <Link to="/">
                                    <img
                                        src="../assets/images/kisanlogo3.jpg"
                                        alt="img"
                                        height="21px"
                                        width="40px"
                                    />
                                </Link>
                                <span
                                    style={{
                                        color: "#767676",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Home
                                </span>
                            </a>
                        </Link>
                    </li>
                    <li className="mobile-category">
                        <Link to="/CategoryScreen">
                            <a>
                                <BiCategoryAlt
                                    style={{ color: "#404040", fontWeight: "600" }}
                                    className="iconly-Category icli js-link"
                                />
                                <span
                                    style={{
                                        color: "#767676",
                                        fontWeight: "500",
                                        fontSize: "11px",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Category
                                </span>
                            </a>
                        </Link>
                    </li>
                    {/* <li>
            <Link to="/">
              <a className="search-box">
                <FiSearch className="iconly-Search icli" />
                <span>Search</span>
              </a>
            </Link>
          </li> */}
                    <li>
                        <Link to="">
                            <a className="notifi-wishlist">
                                {/* <FaRegHeart
                  style={{ color: "#767676", fontWeight: "600" }}
                  className="iconly-Heart icli"
                /> */}
                                {/* <FaFirstOrderAlt  /> */}
                                <BsArrowRepeat
                                    style={{
                                        color: "#404040",
                                        fontWeight: "600",
                                        fontSize: "21px",
                                    }}
                                    className="iconly-Heart icli"
                                />
                                <span
                                    style={{
                                        color: "#767676",
                                        fontWeight: "500",
                                        fontSize: "11px",
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Order Again
                                </span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/cart">
                            <div className="onhover-dropdown header-badge">
                                <a className="position-relative header-wishlist">
                                    <BsCart3
                                        style={{ color: "#404040", fontWeight: "600" }}
                                        className="iconly-Bag-2 icli fly-cate"
                                    />
                                    <span
                                        style={{
                                            color: "#767676",
                                            fontWeight: "500",
                                            fontSize: "11px",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        Cart
                                    </span>
                                    <span
                                        className="position-absolute  translate-middle badge "
                                        style={{
                                            top: "5px",
                                            left: "55px",
                                            backgroundColor: "#f6f6f6",
                                            color: "#04ab66",
                                        }}
                                    >
                                        {CartItems.length}
                                    </span>
                                </a>
                            </div>
                        </Link>
                    </li>
                    <li>
                        {/* <Link to="/login">
              <a className="notifi-wishlist">
                <FaRegUserCircle className="iconly-Heart icli" />
                <span>Account</span>
              </a>
            </Link> */}
                        <li>
                            {isAuth ? (
                                <Link to="/account">
                                    <a style={{ color: "#000" }} className="notifi-wishlist">
                                        <FaRegUserCircle
                                            style={{ color: "#404040", fontWeight: "600" }}
                                            className="iconly-Heart icli"
                                        />
                                        {/* <span style={{width:"100px"}}> {name}</span> */}
                                        <span
                                            style={{
                                                color: "#767676",
                                                fontWeight: "500",
                                                fontSize: "11px",
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                            Account
                                        </span>
                                    </a>
                                </Link>
                            ) : (
                                <Link to="/login">
                                    <a className="notifi-wishlist">
                                        <FaRegUserCircle
                                            style={{ color: "#404040", fontWeight: "600" }}
                                            className="iconly-Heart icli"
                                        />
                                        <span
                                            style={{
                                                color: "#767676",
                                                fontWeight: "500",
                                                fontSize: "11px",
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                            Account
                                        </span>
                                    </a>
                                </Link>
                            )}
                        </li>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Notification
