import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  categoryTotal: localStorage.getItem("categoryTotal")
    ? JSON.parse(localStorage.getItem("categoryTotal"))
    : [],
  isCategoryAvailable: localStorage.getItem("categoryTotal") ? true : false,
  shopbyCategory: localStorage.getItem("categoryTotal")
    ? JSON.parse(localStorage.getItem("categoryTotal")).filter(
        (category) => category.status === true
      )
    : [],
  vegCategory: localStorage.getItem("shopbyCategory")
    ? JSON.parse(localStorage.getItem("shopbyCategory")).filter(
        (category) => category.superCategoryId === "63d8da4ad405398b770b50aa"
      )
    : [],
  nonvegCategory: localStorage.getItem("shopbyCategory")
    ? JSON.parse(localStorage.getItem("shopbyCategory")).filter(
        (category) => category.superCategoryId === "63d8da2bd405398b770b508f"
      )
    : [],
  supermartCategory: localStorage.getItem("shopbyCategory")
    ? JSON.parse(localStorage.getItem("shopbyCategory")).filter(
        (category) => category.superCategoryId === "63d767855658519287238b63"
      )
    : [],

  isCategoryLoading: true,
};

export const getCategory = createAsyncThunk(
  "Category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios.get(url);
      return resp.data.categories;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

//start bulk upload

// post grocery bulk

export const productPost = createAsyncThunk(
  "Category/productPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// post category
export const categoryPost = createAsyncThunk(
  "category/categoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/category/bulk-new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// post sub category
export const subCategoryPost = createAsyncThunk(
  "Category/subCategoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/bulk-new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// end bulk upload

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categoryTotal = action.payload.filter(
        (category) => category.status === true
      );
      state.shopbyCategory = state.categoryTotal;

      localStorage.setItem(
        "categoryTotal",
        JSON.stringify(state.categoryTotal)
      );

      state.isCategoryLoading = false;
      state.isCategoryAvailable = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.isCategoryLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        if (action.payload) {
          state.categoryTotal = action.payload.filter(
            (category) => category.status === true
          );

          state.shopbyCategory = state.categoryTotal.sort((a, b) =>
            a.priority > b.priority ? 1 : -1
          );

          state.vegCategory = state.shopbyCategory.filter(
            (category) =>
              category.superCategoryId === "66cdb9aa4740b2cc1bbdc006"
          );
          state.nonvegCategory = state.shopbyCategory.filter(
            (category) =>
              category.superCategoryId === "66cdb9a94740b2cc1bbdc004"
          );
          state.supermartCategory = state.shopbyCategory.filter(
            (category) =>
              category.superCategoryId === "66cdb9a94740b2cc1bbdc002"
          );

          localStorage.setItem(
            "categoryTotal",
            JSON.stringify(state.categoryTotal)
          );
          localStorage.setItem(
            "shopbyCategory",
            JSON.stringify(state.shopbyCategory)
          );

          state.isCategoryLoading = false;
          state.isCategoryAvailable = true;
        }
      })
      .addCase(getCategory.rejected, (state) => {
        state.isCategoryLoading = false;
      });
  },
});
export const { setCategories } = CategorySlice.actions;
export default CategorySlice.reducer;
