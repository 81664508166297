import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  TodayFeatureTotal: localStorage.getItem("TodayFeatureTotal")
    ? JSON.parse(localStorage.getItem("TodayFeatureTotal"))
    : [],
  VegTodayFeatureSlider: localStorage.getItem("TodayFeatureTotal")
    ? JSON.parse(localStorage.getItem("TodayFeatureTotal")).filter(
        (ban) => ban.superCategory === "Vegetables & fruits"
      )
    : [],
  nonVegTodayFeatureSlider: localStorage.getItem("TodayFeatureTotal")
    ? JSON.parse(localStorage.getItem("TodayFeatureTotal")).filter(
        (ban) => ban.superCategory === "Non-veg"
      )
    : [],
  supermartTodayFeatureSlider: localStorage.getItem("TodayFeatureTotal")
    ? JSON.parse(localStorage.getItem("TodayFeatureTotal")).filter(
        (ban) => ban.superCategory === "Supermart"
      )
    : [],

  isTodayFeaturerAvailable: localStorage.getItem("TodayFeatureTotal")
    ? true
    : false,
  isTodayFeaturerLoading: true,
};

export const getTodayFeaturerBanners = createAsyncThunk(
  "TodayFeature/getTodayFeaturerBanners",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/featuredinweek/all`;
      const resp = await axios.get(url);
      return resp.data.featuredInWeek;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const todayFeatureAllSlice = createSlice({
  name: "TodayFeature",
  initialState,
  reducers: {
    getTodayFeatureset(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTodayFeaturerBanners.pending, (state) => {
        state.isTodayFeaturerLoading = true;
      })
      .addCase(getTodayFeaturerBanners.fulfilled, (state, action) => {
        if (action.payload) {
          state.TodayFeatureTotal = action.payload;
          state.VegTodayFeatureSlider = state.TodayFeatureTotal.filter(
            (ban) => ban.superCategory === "Vegetables & fruits"
          );
          state.nonVegTodayFeatureSlider = state.TodayFeatureTotal.filter(
            (ban) => ban.superCategory === "Non-veg"
          );
          state.supermartTodayFeatureSlider = state.TodayFeatureTotal.filter(
            (ban) => ban.superCategory === "Supermart"
          );

          localStorage.setItem(
            "TodayFeatureTotal",
            JSON.stringify(state.TodayFeatureTotal)
          );

          state.isTodayFeaturerAvailable = true;
        }
        state.isTodayFeaturerLoading = false;
      })
      .addCase(getTodayFeaturerBanners.rejected, (state) => {
        state.isTodayFeaturerLoading = false; 
      });
  },
});

export const { getTodayFeatureset } = todayFeatureAllSlice.actions;
export default todayFeatureAllSlice.reducer;
