import React, { useEffect, useState, useRef } from "react";
  import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoIosArrowForward } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import { MdDeleteOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import CartSlider from "../../component/cartPart/CartSlider";

const CartProducts = () => {
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const {
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
  } = useSelector((state) => state.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems, dispatch]);

  const removefromcart = (cartItem) => {
    dispatch(removefromCart(cartItem));
    dispatch(getCartTotal());
  };

  const handledecreasequtcart = (cartItem) => {
    dispatch(decrementCart(cartItem));
    dispatch(getCartTotal());
  };

  const handleincreasequtcart = (cartItem) => {
    dispatch(addtoCart(cartItem));
    dispatch(getCartTotal());
  };

  return (
    <>
      <div className="col-xxl-12 laptopversioncart">
        <div className="cart-table">
          <div className="table-responsive-xl">
            <table className="table">
              <tbody>
                {CartItems &&
                  CartItems.map((cart, index) => (
                    <tr className="product-box-contain" key={index}>
                      <td className="product-detail">
                        <div className="product border-0">
                          <a className="product-image">
                            <img
                              src={cart.ImgUrl}
                              className="img-fluid blur-up lazyload"
                              alt=""
                            />
                          </a>
                          <div className="product-detail">
                            <ul>
                              <li className="name">
                                <a>{cart.ItemName}</a>
                              </li>
                              <li className="text-content">
                                <span className="text-title">Sold By:</span>{" "}
                                {cart.Brand}
                              </li>
                              <li className="text-content">
                                <span className="text-title">Quantity</span> -
                                {cart.PackSize}
                              </li>
                              <li>
                                <h5 className="text-content d-inline-block">
                                  Price :
                                </h5>
                                <span>₹{cart.Price}</span>
                                <span className="text-content">
                                  ₹{cart.Mrp}
                                </span>
                              </li>
                              <li>
                                <h5 className="saving theme-color">
                                  Saving : ₹20.68
                                </h5>
                              </li>
                              <li className="quantity-price-box">
                                <div className="cart_qty">
                                  <div className="input-group">
                                    <button
                                      type="button"
                                      className="btn qty-left-minus"
                                      data-type="minus"
                                      data-field
                                    >
                                      <i
                                        className="fa fa-minus ms-0"
                                        aria-hidden="true"
                                      />
                                    </button>
                                    <input
                                      className="form-control input-number qty-input"
                                      type="text"
                                      name="quantity"
                                      defaultValue={0}
                                    />
                                    <button
                                      type="button"
                                      className="btn qty-right-plus"
                                      data-type="plus"
                                      data-field
                                    >
                                      <i
                                        className="fa fa-plus ms-0"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <h5>Total: ₹35.10</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td className="price">
                        <h4 className="table-title text-content">Price</h4>
                        <h5>
                          ₹{cart.Price}{" "}
                          {cart.Price < cart.Mrp ? (
                            <del className="text-content">₹{cart.Mrp}</del>
                          ) : (
                            <></>
                          )}
                        </h5>
                        <h6 className="theme-color">
                          {cart.Price < cart.Mrp ? (
                            <span>You Save : ₹{cart.Mrp - cart.Price}</span>
                          ) : (
                            <></>
                          )}
                        </h6>
                      </td>
                      <td className="quantity">
                        <h4 className="table-title text-content">Qty</h4>
                        <div className="quantity-price">
                          <div className="cart_qty">
                            <div className="input-group">
                              <button
                                type="button"
                                className="btn qty-left-minus"
                                data-type="minus"
                                data-field
                                onClick={() => {
                                  if (cart.Qty > 0) {
                                    handledecreasequtcart(cart);
                                  }
                                }}
                              >
                                <i
                                  className="fa fa-minus ms-0"
                                  aria-hidden="true"
                                />
                              </button>

                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                                value={cart.Qty}
                                contentEditable="false"
                              />
                              <button
                                type="button"
                                className="btn qty-right-plus"
                                data-type="plus"
                                data-field
                                onClick={() => {
                                  if (10 >= cart.Qty) {
                                    handleincreasequtcart(cart);
                                  }
                                }}
                              >
                                <i
                                  className="fa fa-plus ms-0"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="subtotal">
                        <h4 className="table-title text-content">Total</h4>
                        <h5>₹{cart.TotalAmount}</h5>
                      </td>
                      <td className="save-remove">
                        <h4 className="table-title text-content">Action</h4>
                        <a
                          className="remove close_button"
                          onClick={() => removefromcart(cart)}
                          style={{ cursor: "pointer" }}
                        >
                          Remove
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="viewcouponscontainer">
        {" "}
        <div
          style={{
            backgroundColor: "#fff",
            margin: "5px",
            padding: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BiSolidOffer size={25} color="#00ab66" />
              <h5 style={{ paddingLeft: "6px", fontWeight: "600" }}>
                View Coupons & Offers
              </h5>
            </div>
            <IoIosArrowForward size={20} color="#00ab66" />
          </div>
        </div>
        {CartItems &&
          CartItems.map((cart, index) => (
            <div
              className="swipersubcontainer1"
              style={{ margin: "10px", width: "95%" }}
            >
              <div className="swipersubcontainer2">
                <div>
                  <img
                    src={cart.ImgUrl}
                    alt="image"
                    height="40px"
                    width="40px"
                  />
                </div>
                <div className="swipersubcontainer3">
                  <div>
                    <h6 style={{ fontWeight: "600" }}>{cart.ItemName}</h6>
                    <span>{cart.PackSize}</span>
                  </div>
                </div>
              </div>
              <div className="swipersubcontainer2">
                {/* <div className="sliderbtn">
                      <p style={{ color: "red", fontWeight: "600" }}>Unlock</p>
                    </div> */}
                <div
                  style={{
                    display: "flex",
                    width: "63px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "30px",
                    backgroundColor: "#00ab66",
                    color: "#fff",
                    borderRadius: "5px",
                    padding: "2PX",
                  }}
                >
                  <FiMinus onClick={() => handledecreasequtcart(cart)} />
                  <span>{cart.Qty}</span>
                  <GoPlus onClick={() => handleincreasequtcart(cart)} />
                </div>
                <div style={{ width: "40px" }}>
                  <div className="sliderlastbtn">
                    <LiaRupeeSignSolid />
                    <span style={{ fontWeight: "600", fontFamily: "Poppins" }}>
                      {cart.Price}
                    </span>
                  </div>
                  <div className="sliderlastbtn1">
                    <LiaRupeeSignSolid color="#ccc" />
                    <span
                      style={{
                        textDecorationLine: "line-through",
                        color: "#ccc",
                        fontFamily: "Poppins",
                      }}
                    >
                      {cart.Mrp}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* <CartSlider /> */}

      {/* {CartItems &&
        CartItems.map((cart, index) => (
          <div className="mobileCart ">
           
            <div className="MiddleCart">
              <div className="ImageContainer">
                <img src={cart.ImgUrl} className="imagecart" alt="" />
              </div>
              <div className="detailContainer">
              <div className="productName">
              <p>{cart.ItemName}</p>
                </div>
              
                 <p>
                  <span className="text-title" style={{color:"#000",fontWeight:"700"}}>Sold By:</span>{" "}
                                {cart.Brand}
               </p>

                <div className="price">
                 
                  <p>
                    {" "}
                    <span className="priceAmount"> ₹{cart.Price} </span>{" "}
                    <span className="DisAmount">
                      {" "}
                      <del>₹{cart.Mrp}</del>
                    </span>
                  </p>
                
                </div>
                <div className="CartRight">
                  <div className=" quantity">
                    <button
                      type="button"
                      className="button1"
                      data-type="minus"
                      data-field
                      onClick={() => handledecreasequtcart(cart)}
                    >
                      <i className="fa fa-minus" aria-hidden="true" />
                    </button>
                    <input
                      className="form-control middlepart"
                      type="text"
                      name="quantity"
                      defaultValue={0}
                      value={cart.Qty}
                      contentEditable="false"
                    />
                    <button
                      type="button"
                      className="button1"
                      data-type="plus"
                      data-field
                      onClick={() => handleincreasequtcart(cart)}
                    >
                      <i className="fa fa-plus ms-0" aria-hidden="true" />
                    </button>
                  </div>
                  <p>
                    <span
                      className="remove1"
                      onClick={() => removefromcart(cart)}
                      style={{ cursor: "pointer" }}
                    >
                      <RiDeleteBin5Line />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))} */}
    </>
  );
};

export default CartProducts;
