import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import LZString from "lz-string";

const initialState = {
  CartItems: localStorage.getItem("CartItems")
    ? JSON.parse(localStorage.getItem("CartItems"))
    : [],
  ProductTotal:[],
  isProductAvailable: localStorage.getItem("ProductTotal") ? true : false,
  isProductLoading: true,

  OrganicVegetable: localStorage.getItem("OrganicVegetable")
    ? JSON.parse(
        LZString.decompressFromUTF16(localStorage.getItem("OrganicVegetable"))
      )
    : [],
  isOrganicVegAvailable: localStorage.getItem("OrganicVegetable")
    ? true
    : false,
  isOrganicVegLoading: true,
  OrganicFruit: localStorage.getItem("OrganicFruit")
    ? JSON.parse(
        LZString.decompressFromUTF16(localStorage.getItem("OrganicFruit"))
      )
    : [],
  isOrganicFruitAvailable: localStorage.getItem("OrganicFruit") ? true : false,
  isOrganicFruitLoading: true,

  groceryProduct: [],
  groceryHotProduct: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  netPayable: 0,
  updateNetPayable: 0,
  cartTotalMrp: 0,
  cartTotalPrice: 0,
  cartPackQuantity: 0,
  cartHandling: 0,
  cartCouponDiscount: 0,
  CouponDiscountAmount: 0,
  cartCouponCode: "",
  cartCoupon: "",
  logisticsAmount: 40,
  usedWalletAmount: 0,
  productUpdated: false,
  Cartloading: true,
  //search
  subcatSearchProducts: [],
  searchSlugUrl: "",
  catSearchProducts: [],
  catsearchSlugUrl: "",
  SearchProducts: [],
  prosearchSlugUrl: "",
  vegCatProduct: [],
  freshfruitCatProduct: [],
  cleaningessentialCatProduct: [],
  pharamCatProduct: [],
  dryfruitCatProduct: [],
  chikenCatProduct: [],
  muttonCatProduct: [],
  fishCatProduct: [],
};

export const getProduct = createAsyncThunk(
  "Product/getProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const resp = await axios.get(url);
      return resp.data.grocery;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getOrganicVegetable = createAsyncThunk(
  "Product/getOrganicVegetable",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/grocerybysubcatid/66d556e7187ebd7324e25b39`;
      const resp = await axios.get(url);
      return resp.data.grocery;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getOrganicFruit = createAsyncThunk(
  "Product/getOrganicFruit",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/grocerybysubcatid/66d55678187ebd7324e259de`;
      const resp = await axios.get(url);
      return resp.data.grocery;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const CartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    InitialmovetoCart(state, action) {
      state.CartItems = action.payload;
    },
    updateCart(state, action) {
      state.CartItems = action.payload;
    },
    addtoCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.CartItems.findIndex(
        (item) => item.packsizeId === action.payload.packsizeId
      );

      if (itemIndex >= 0) {
        state.cartTotalQuantity++;
        let quant = state.CartItems[itemIndex].Qty + 1;

        state.CartItems[itemIndex].Qty = state.CartItems[itemIndex].Qty + 1;
        state.CartItems[itemIndex].TotalMrp = Number(
          state.CartItems[itemIndex].Mrp * Number(quant)
        );
        state.CartItems[itemIndex].TotalPrice = Number(
          state.CartItems[itemIndex].SellingPrice * Number(quant)
        );
        state.CartItems[itemIndex].TotalAmount = Number(
          state.CartItems[itemIndex].SellingPrice * Number(quant)
        );

        state.cartTotalAmount =
          Number(state.cartTotalAmount) + Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }
        state.netPayable =
          Number(state.cartTotalAmount) + Number(state.logisticsAmount);
      } else {
        const tempProducts = action.payload;
        state.CartItems.push(tempProducts);

        state.cartTotalQuantity++;
        state.cartPackQuantity++;

        state.cartTotalAmount =
          Number(state.cartTotalAmount) + Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }

        state.netPayable =
          Number(state.cartTotalAmount) + Number(state.logisticsAmount);
      }
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    decrementCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.CartItems.findIndex(
        (item) => item.packsizeId === action.payload.packsizeId
      );

      if (itemIndex >= 0) {
        if (state.CartItems[itemIndex].Qty > 1) {
          let quant = state.CartItems[itemIndex].Qty - 1;
          state.CartItems[itemIndex].Qty = state.CartItems[itemIndex].Qty - 1;
          state.CartItems[itemIndex].TotalMrp = Number(
            state.CartItems[itemIndex].Mrp * Number(quant)
          );

          state.CartItems[itemIndex].TotalPrice = Number(
            state.CartItems[itemIndex].SellingPrice * Number(quant)
          );
          state.CartItems[itemIndex].TotalAmount = Number(
            state.CartItems[itemIndex].SellingPrice * Number(quant)
          );
        } else if (state.CartItems[itemIndex].Qty === 1) {
          const nextCartItems = state.CartItems.filter(
            (cartItem) => cartItem.packsizeId !== action.payload.packsizeId
          );
          state.cartPackQuantity--;
          state.CartItems = nextCartItems;
        }

        state.cartTotalAmount =
          Number(state.cartTotalAmount) - Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }

        state.netPayable =
          Number(state.cartTotalAmount) + Number(state.logisticsAmount);
        state.Cartloading = !state.Cartloading;
        localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
      }
    },
    removefromCart(state, action) {
      const nextCartItems = state.CartItems.filter(
        (cartItem) => cartItem.packsizeId !== action.payload.packsizeId
      );
      state.cartPackQuantity--;
      state.CartItems = nextCartItems;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    getCartTotal(state, action) {
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartTotalPrice = 0;
      const totalitem = state.CartItems;

      for (let i = 0; i < totalitem.length; i++) {
        state.cartTotalAmount =
          Number(state.cartTotalAmount) +
          Number(state.CartItems[i].TotalAmount);
        state.cartTotalMrp =
          Number(state.cartTotalMrp) + Number(state.CartItems[i].TotalMrp);
        state.cartTotalPrice =
          Number(state.cartTotalPrice) + Number(state.CartItems[i].TotalPrice);
      }

      if (state.cartTotalAmount >= 500) {
        state.logisticsAmount = 0;
      } else {
        state.logisticsAmount = 40;
      }
      state.netPayable =
        Number(state.cartTotalAmount) + Number(state.logisticsAmount);
    },
    clearCart(state, action) {
      state.CartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartPackQuantity = 0;
      state.Cartloading = !state.Cartloading;

      localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
    },
    couponUpdate(state, action) {
      state.cartCoupon = action.payload;
    },
    setCouponDiscountAmount(state, action) {
      if (state.cartTotalAmount >= state.cartCoupon.MinAmount) {
        state.CouponDiscountAmount = action.payload;
      } else {
        state.CouponDiscountAmount = 0;
      }
    },
    setcartCouponDiscount(state, action) {
      state.cartCouponDiscount = action.payload;
    },

    //search
    setSubcatSearchProducts(state, action) {
      state.subcatSearchProducts = action.payload;
      state.catSearchProducts = [];
      state.SearchProducts = [];
    },
    setSearchSlugUrl(state, action) {
      state.searchSlugUrl = action.payload;
      state.catsearchSlugUrl = "";
      state.prosearchSlugUrl = "";
    },
    setCatSearchProducts(state, action) {
      state.catSearchProducts = action.payload;
      state.subcatSearchProducts = [];
      state.SearchProducts = [];
    },
    setCatsearchSlugUrl(state, action) {
      state.catsearchSlugUrl = action.payload;
      state.searchSlugUrl = "";
      state.prosearchSlugUrl = "";
    },
    setSearchProducts(state, action) {
      state.SearchProducts = action.payload;
    },
    setProsearchSlugUrl(state, action) {
      state.prosearchSlugUrl = action.payload;
      state.searchSlugUrl = "";
      state.catsearchSlugUrl = "";
    },

    setsearchEmplty(state, action) {
      state.searchSlugUrl = "";
      state.subcatSearchProducts = [];
      state.catsearchSlugUrl = "";
      state.catSearchProducts = [];
      state.prosearchSlugUrl = "";
      state.SearchProducts = [];
    },
    netpayableUpdate(state, action) {
      state.updateNetPayable = action.payload;
    },
    walletamountUpdate(state, action) {
      state.usedWalletAmount = action.payload;
    },
    setgetCart(state, action) {
      state.CartItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const filteredProducts = action.payload.filter(
            (product) => product.PackSizes.length !== 0
          );

          state.ProductTotal = filteredProducts;
          state.groceryProduct = filteredProducts.filter(
            (product) => product.superCategoryId === "66cdb9aa4740b2cc1bbdc006"
          );
          state.groceryHotProduct = filteredProducts.filter(
            (product) => product.HotProducts === true
          );

          localStorage.setItem(
            "ProductTotal",
            LZString.compressToUTF16(JSON.stringify(state.ProductTotal))
          );
          localStorage.setItem(
            "groceryProduct",
            LZString.compressToUTF16(JSON.stringify(state.groceryProduct))
          );

          state.isProductLoading = false;
          state.isProductAvailable = true;
        }
      })
      .addCase(getProduct.rejected, (state) => {
        state.isProductLoading = false;
      })

      //Organic Product
      .addCase(getOrganicVegetable.pending, (state) => {
        state.isOrganicVegLoading = true;
      })
      .addCase(getOrganicVegetable.fulfilled, (state, action) => {
        if (action.payload) {
          const filteredOrganicProduct = action.payload.filter(
            (data) => data.OutOfStack === false && data.PackSizes.length !== 0
          );
          state.OrganicVegetable = filteredOrganicProduct;
          localStorage.setItem(
            "OrganicVegetable",
            LZString.compressToUTF16(JSON.stringify(state.OrganicVegetable))
          );
          state.isOrganicVegLoading = false;
          state.isOrganicVegAvailable = true;
        }
      })
      .addCase(getOrganicVegetable.rejected, (state) => {
        state.isOrganicVegLoading = false;
      })

      //Organic fruit
      .addCase(getOrganicFruit.pending, (state) => {
        state.isOrganicFruitLoading = true;
      })
      .addCase(getOrganicFruit.fulfilled, (state, action) => {
        if (action.payload) {
          const filteredOrganicFruit = action.payload.filter(
            (data) => data.OutOfStack === false && data.PackSizes.length !== 0
          );
          state.OrganicFruit = filteredOrganicFruit;
          localStorage.setItem(
            "OrganicFruit",
            LZString.compressToUTF16(JSON.stringify(state.OrganicFruit))
          );
          state.isOrganicFruitLoading = false;
          state.isOrganicFruitAvailable = true;
        }
      })
      .addCase(getOrganicFruit.rejected, (state) => {
        state.isOrganicFruitLoading = false;
      });
  },
});

export const {
  updateCart,
  InitialmovetoCart,
  addtoCart,
  decrementCart,
  getCartTotal,
  clearCart,
  allProductUpdate,
  setSubcatSearchProducts,
  setSearchSlugUrl,
  setCatSearchProducts,
  setCatsearchSlugUrl,
  setSearchProducts,
  setProsearchSlugUrl,
  setsearchEmplty,
  couponUpdate,
  setCouponDiscountAmount,
  setcartCouponDiscount,
  removefromCart,
  netpayableUpdate,
  walletamountUpdate,
  primaeryChange,
  setgetCart,
} = CartSlice.actions;
export default CartSlice.reducer;
