import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { LoginOtp, loginAuthentication } from '../redux/athentication/Athentication';

const SmsSend = () => {
    const params = useParams()

    const dispatch = useDispatch()

    const [mobile, setMobile] = useState("")
    useEffect(() => {
        let number = params.mob
        let otpWithHash = params.otpWithHash

        if (number.length === 10) {

            // async function fetchData() {
            //     const res = await fetch(
            //         `https://mobicomm.dove-sms.com/submitsms.jsp?user=shantire&key=2ad16bc0f9XX&mobile=+91${number}&message=Dear Customer, Your OTP for logging in to KiranaWorld account is ${otpWithHash}. Do not share with anyone. Regards Team Kirana World, a Unit of Shanti Retails.&senderid=KRNWLD&accusage=1&entityid=1201159194292972286&tempid=1407168898514000516`
            //     );
            //     res
            //         .json()
            //         .then((res) => res)
            //         .catch((err) => err);
            // }
            // fetchData();
            const formdata = {
                number: number,
                OTP: otpWithHash,
            };
            dispatch(loginAuthentication(formdata))
        }
    }, [params.mob, params.otpWithHash])

    return (
        <div>{params.mob},{params.otpWithHash}</div>
    )
}

export default SmsSend