import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../redux/athentication/Athentication";
import {
  gerenatenewTokrnId,
  getOrderbyClId,
  updateOrder,
} from "../redux/order/OrderSlice";
import axios from "axios";

const OrderScreen = () => {
  const { clientOrder } = useSelector((state) => state.Order);

  const { loginData, clientid } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const orderCancelClick = async (e, selectOrder) => {
    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
    } else {
      var OrderprocessDate = {};
    }

    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: selectOrder.OrderprocessDate.OutfordeliveryDate,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: date,
      };
    } else {
      var OrderprocessDate = {};
    }

    const formData = {
      Status: 0,
      StatusText: "Order Cancelled",
      orderid: selectOrder._id,
      remark: {
        OrderCancelRemark: "Your delivery is cancelled",
      },
      OrderprocessDate: OrderprocessDate,
    };
    const data = await dispatch(updateOrder(formData));

    const getClientForm = {
      Mobile: selectOrder.Mobile,
    };

    const getOrderClient = await dispatch(getClientByMob(getClientForm));

    let getclient = getOrderClient.payload.client;
    if (selectOrder.CurrentStatus === "Not Paid") {
      if (selectOrder.wallet > 0) {
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          claintid: selectOrder.ClientId,
        };
        dispatch(clientUpdate(formDta));

        const walletForm = {
          ClientId: getclient._id,
          previousAmount: getclient.WalletBalance,
          availableAmount:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          transactionAmount: Number(selectOrder.wallet),
          orderId: selectOrder._id,
          Type: "Wallet Credit on Order Cancel",
          ClientName: getclient.Name,
          Mobile: getclient.Mobile,
        };
        await dispatch(walletTransitionPost(walletForm));
      }
    } else if (selectOrder.CurrentStatus === "Paid") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) + Number(selectOrder.TotalAmount),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) + Number(selectOrder.wallet),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    } else if (selectOrder.CurrentStatus === "Partial Payment") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Partial Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));

      dispatch(getOrderbyClId(clientid));
    }

    const sendOptthowwp = async () => {
      const name = selectOrder.ClientName;
      const orderId = String(selectOrder._id.slice(18).toUpperCase());
      const amount = String(selectOrder.GrandTotal);
      const mobile = selectOrder.Mobile;
      let data = JSON.stringify({
        countryCode: "+91",
        phoneNumber: mobile,
        callbackData: "some text here",
        type: "Template",
        template: {
          name: "order_cancelled",
          languageCode: "en",
          bodyValues: [name, orderId, amount],
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.interakt.ai/v1/public/message/",
        headers: {
          Authorization:
            "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    sendOptthowwp();
  };
  return (
    // <div
    //   style={{
    //     height: "100vh",
    //     width: "100%",
    //     overflow: "scroll",
    //     display: "flex",
    //     justifyContent: "center",
    //   }}
    //   className="Orderbody"
    // >
    //   {clientOrder.length > 0 ? (
    //     <>
    //       <div className="m-1 w-100 ">
    //         {clientOrder.map((order, index) => (
    //           <div
    //             className="p-2 mt-2 border border-2 rounded-2"
    //             style={{ height: "280px" }}
    //             key={index}
    //           >
    //             <div>
    //               <table className="table table-striped">
    //                 <tbody>
    //                   <tr>
    //                     <td className="text-success">Net Payable</td>
    //                     <td className="text-danger">₹{order.GrandTotal}</td>
    //                   </tr>

    //                   <tr>
    //                     <td className="text-success">Order Status</td>
    //                     <td className="text-danger"> {order.StatusText}</td>
    //                   </tr>
    //                   <tr>
    //                     <td className="text-success">Payment Type</td>
    //                     <td className="text-danger">{order.PaymentMode}</td>
    //                   </tr>
    //                   <tr>
    //                     <td className="text-success">Payment Status</td>
    //                     <td className="text-danger"> {order.CurrentStatus}</td>
    //                   </tr>
    //                   <tr>
    //                     <td className="text-success">Transaction Status</td>
    //                     <td className="text-danger">{order.PaymentStatus}</td>
    //                   </tr>
    //                   <tr>
    //                     <td className="text-success">Order Date</td>
    //                     <td className="text-danger">
    //                       {" "}
    //                       {moment(order.createdAt).format("MMM DD YY")}
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td className="text-success">Expected Delivery Date</td>
    //                     <td className="text-danger">
    //                       {" "}
    //                       {moment(order.ExpectedDelDate).format("MMM DD YY")}
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>

    //             <div className="d-flex justify-content-between ">
    //               <Link to={`/ordertracking/${order._id}`}>
    //                 <button className="btn btn-success" style={{width:"170px"}}>view Order</button>
    //               </Link>
    //               {order.Status === 1 ? (
    //                 <>
    //                   <button
    //                     className="btn btn-danger" style={{width:"170px"}}
    //                     onClick={(e) => orderCancelClick(e, order)}
    //                   >
    //                     Order Cancel
    //                   </button>
    //                 </>
    //               ) : (
    //                 <></>
    //               )}
    //             </div>
    //           </div>
    //         ))}
    //       </div>
    //     </>
    //   ) : (
    //     <></>
    //   )}
    // </div>
    <div>
      <div className="mainOrderpage">
        <div className="subContainer">
          {clientOrder.length > 0 ? (
            <>
              <div className="card">
                {clientOrder.map((order, index) => (
                  <>
                    <div className="subContpart1" key={index}>
                      <div className="Imagepart">
                        <img
                          src="../assets/images/cart.png"
                          alt=""
                          className="Imagee"
                        />
                      </div>
                      <div className="Detailspart">
                        <div className="orderinfo">
                          <span className="textt">Net Payable:</span>
                          <span className="colortextt">
                            {" "}
                            ₹{order.GrandTotal}
                          </span>
                        </div>
                        <div className="orderinfo">
                          <span className="textt">Order Status:</span>
                          <span className="colortextt">{order.StatusText}</span>
                        </div>
                        <div className="orderinfo">
                          <span className="textt">Payment Type:</span>
                          <span className="colortextt">
                            {order.PaymentMode}
                          </span>
                        </div>
                        <div className="orderinfo">
                          <span className="textt">Payment Status:</span>
                          <span className="colortextt">
                            {order.CurrentStatus}
                          </span>
                        </div>
                        <div className="orderinfo">
                          <span className="textt">Transaction Status:</span>
                          <span className="colortextt">
                            {order.PaymentStatus}
                          </span>
                        </div>
                        <div className="orderinfo">
                          <span className="textt">Order Date:</span>
                          <span className="colortextt">
                            {moment(order.createdAt).format("MMM DD YY")}
                          </span>
                        </div>
                        <div className="orderinfo">
                          <span className="textt">Expected Delivery Date:</span>
                          <span className="colortextt">
                            {moment(order.ExpectedDelDate).format("MMM DD YY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="subContpart2">
                      <Link to={`/ordertracking/${order._id}`}>
                        <button className="cancontainer">View Order</button>
                      </Link>
                      {order.Status === 1 ? (
                        <>
                          <button
                            className="cancontainer1"
                            onClick={(e) => orderCancelClick(e, order)}
                          >
                            Order Cancel
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderScreen;
