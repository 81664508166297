import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { addtoCart, decrementCart } from "../../../redux/cart/CartSlice";
import axios from "axios";
import { Baseurl } from "../../../config/BaseUrl";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";

const OrganicVegetable = () => {
  const { OrganicVegetable, CartItems } = useSelector((state) => state.Cart);
  const dispatch = useDispatch();
  const [toChangeColor, setToChangeColor] = useState(false);
  const [hotProducts, setHotProducts] = useState([]);
  const [protest, setProtest] = useState(false);

  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };
  const bg = {
    overlay: {
      background: "#FFFF00",
    },
  };

  useEffect(() => {
    try {
      let prod = OrganicVegetable;

      let hotproducts = prod.map((product) => ({
        ...product,
        PackSizes: product.PackSizes.map((pack) => ({ ...pack })),
      }));

      CartItems.forEach((cartitem) => {
        let itemIndex = hotproducts.findIndex(
          (product) => String(product._id) === String(cartitem.ProductId)
        );
        if (itemIndex >= 0) {
          let packIndex = hotproducts[itemIndex].PackSizes.findIndex(
            (pack) => String(pack._id) === String(cartitem.packsizeId)
          );
          if (packIndex >= 0) {
            hotproducts[itemIndex].PackSizes[packIndex].CartQuantity =
              cartitem.Qty;
          }
        }
      });
      setHotProducts(hotproducts);
      const verifyProduct = hotproducts.find(
        (data) => data.Url === "red-capsicum--organic"
      );
      if (verifyProduct) {
        hotproducts = hotproducts.filter(
          (data) =>
            data.Url !== "red-capsicum--organic" &&
            data.Url !== "lettuce-romaine-green-organic"
        );
        hotproducts = [verifyProduct, ...hotproducts];
      }
      setHotProducts(hotproducts);
    } catch (error) { }
  }, [CartItems, OrganicVegetable]);

  const addtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = [...hotProducts];

      products[itemIndex].PackSizes[packIndex].CartQuantity += 1;

      setHotProducts([...products]);
      setProtest(!protest);
    } catch (error) { }

    const formData = {
      ProductId: hotPro._id,
      ProductName: hotPro.ItemName,
      CatName: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      ItemName: hotPro.ItemName,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      Description: hotPro.Description,
      ImgUrl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      Price: Number(hotPro.PackSizes[packIndex].SellingPrice),
      Qty: 1,
      TotalAmount: Number(hotPro.PackSizes[packIndex].SellingPrice) * 1,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      TotalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      Cashback: hotPro.Cashback,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      maximumQuantity: hotPro.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = hotProducts;
      products[itemIndex].PackSizes[packIndex].CartQuantity -= 1;

      setHotProducts(products);
    } catch (error) { }

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  return (
    <>
      {hotProducts.length > 0 ? (
        <>
          <section>
            <div className="container">
              <div className="title">
                <h2
                  className="hotproducttext"
                  style={{
                    textAlign: "center",
                  }}
                >
                  Organic Vegetable
                </h2>
                {/* <span className="title-leaf">
                  <svg className="icon-width">
                    <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                  </svg>
                </span> */}
              </div>
              <div className="product-border border-row mt-1">
                <div className="slider-6_2 no-arrow">
                  <Swiper
                    // draggable={true}
                    grabCursor={true}
                    navigation={false}
                    pagination={false}
                    mousewheel={false}
                    keyboard={true}
                    modules={[
                      Navigation,
                      Pagination,
                      Mousewheel,
                      Keyboard,
                      // Autoplay,
                    ]}
                    // loop={true}
                    // autoplay={{
                    //   delay: 1500,
                    //   disableOnInteraction: false,
                    //   pauseOnMouseEnter: true,
                    // }}
                    breakpoints={{
                      360: {
                        slidesPerView: 2.6,
                        spaceBetween: 5,
                      },
                      460: {
                        slidesPerView: 2.6,
                        spaceBetween: 5,
                      },
                      720: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                      },
                      1080: {
                        slidesPerView: 5.8,
                        spaceBetween: 7,
                      },
                    }}
                    className="mySwiper"
                  >
                    <>
                      {hotProducts &&
                        hotProducts.slice(0, 12).map((product, itemIndex) => (
                          <SwiperSlide key={product._id}>
                            <div className=" px-0 mt-2">
                              <div className="product-box wow fadeIn">
                                {product.PackSizes.map((pack, packIndex) =>
                                  pack.Pri === true ? (
                                    <>
                                      <Link to={`/product-info/${product.Url}`}>
                                        <div
                                          className="product-image"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <a>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              className="img-fluid blur-up lazyload offertext"
                                              alt
                                            />
                                            <span id="lorel">
                                              {pack.Discount}% off
                                            </span>
                                            <span id="lorel1">
                                              {pack.Discount}% off
                                            </span>
                                          </a>
                                        </div>
                                      </Link>

                                      <div className="product-detail paddtext">
                                        <h6 className="sold weight text-content fw-normal">
                                          {product.Brand}
                                        </h6>
                                        <a>
                                          <h6
                                            className="name name-2 h-100"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {product.ItemName}
                                          </h6>
                                        </a>

                                        <h6
                                          className="sold"
                                          style={{
                                            color: "#000",
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          ₹{pack.SellingPrice}
                                          {pack.Mrp === pack.SellingPrice ? (
                                            <> </>
                                          ) : (
                                            <>
                                              <span
                                                style={{
                                                  color: "#ccc",
                                                  fontSize: "12px",
                                                  textDecorationLine:
                                                    "line-through",
                                                  paddingLeft: "1vh",
                                                }}
                                              >
                                                ₹{pack.Mrp}
                                              </span>
                                            </>
                                          )}
                                        </h6>
                                        <div className="counter-box mt-1">
                                          {product.Multi === true ? (
                                            <>
                                              <h6
                                                className="sold weight text-content fw-normal kg_text"
                                                style={{
                                                  color: "#000",
                                                  fontFamily: "Poppins",
                                                  fontWeight: "bold",
                                                  textTransform: "lowercase",
                                                }}
                                                onClick={() =>
                                                  optionsProductset(
                                                    product,
                                                    itemIndex
                                                  )
                                                }
                                              >
                                                {/* {pack.PackSize} */}
                                                {pack.PackSize.length > 6
                                                  ? `${pack.PackSize.slice(
                                                    0,
                                                    6
                                                  )}...`
                                                  : pack.PackSize}{" "}
                                                <IoIosArrowDown />
                                              </h6>
                                            </>
                                          ) : (
                                            <>
                                              <h6
                                                className="sold weight text-content fw-normal kg_text"
                                                style={{
                                                  color: "#000",
                                                  fontFamily: "Poppins",
                                                  fontWeight: "bold",
                                                  textTransform: "lowercase",
                                                }}
                                              >
                                                {/* {pack.PackSize} */}
                                                {pack.PackSize.length > 6
                                                  ? `${pack.PackSize.slice(
                                                    0,
                                                    6
                                                  )}...`
                                                  : pack.PackSize}
                                              </h6>
                                            </>
                                          )}

                                          <div className="addtocart_btn addtocart_btn10">
                                            {pack.OutOfStack === true ? (
                                              <>
                                                <button className="add-button addcart-button btn buy-button text-light">
                                                  <span className="add_text">
                                                    out of stock
                                                  </span>
                                                  <h4 className="add_text showoption">
                                                    out of stock
                                                  </h4>
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                {product.Multi === true ? (
                                                  <>
                                                    {hotProducts[itemIndex]
                                                      .PackSizes[
                                                      packIndex
                                                    ].CartQuantity > 0 ? (<>

                                                      <div className="qty-box cart_qty open">
                                                        <div className="input-group">
                                                          <button
                                                            type="button"
                                                            style={{
                                                              backgroundColor:
                                                                "#00ab66",
                                                            }}
                                                            className="btn qty-left-minus"
                                                            data-type="minus"
                                                            data-field
                                                            onClick={(e) =>
                                                              decrementtocartPress(
                                                                e,
                                                                product,
                                                                itemIndex,
                                                                packIndex
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              className="fa fa-minus"
                                                              aria-hidden="true"
                                                              style={{
                                                                color: "#fff",
                                                              }}
                                                            />
                                                          </button>
                                                          <div
                                                            className="form-control input-number qty-input"
                                                            style={{
                                                              backgroundColor:
                                                                "#00ab66",
                                                              border: "unset",
                                                              paddingTop:
                                                                "3px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: "#fff",
                                                              }}
                                                            >
                                                              {
                                                                hotProducts[
                                                                  itemIndex
                                                                ]
                                                                  .PackSizes[
                                                                  packIndex
                                                                ].CartQuantity
                                                              }
                                                            </span>
                                                          </div>

                                                          {11 ===
                                                            hotProducts[
                                                              itemIndex
                                                            ].PackSizes[
                                                              packIndex
                                                            ].CartQuantity ? (
                                                            <>
                                                              <button
                                                                type="button"
                                                                className="btn qty-right-plus"
                                                                data-type="plus"
                                                                data-field
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                />
                                                              </button>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <button
                                                                type="button"
                                                                style={{
                                                                  backgroundColor:
                                                                    "#00ab66",
                                                                }}
                                                                className="btn qty-right-plus"
                                                                data-type="plus"
                                                                data-field
                                                                onClick={(
                                                                  e
                                                                ) =>
                                                                  addtocartPress(
                                                                    e,
                                                                    product,
                                                                    itemIndex,
                                                                    packIndex
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                  style={{
                                                                    color:
                                                                      "#fff",
                                                                  }}
                                                                />
                                                              </button>
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>) : (<>
                                                      <button
                                                        className="add-button addcart-button btn buy-button text-light"
                                                        onClick={(e) =>
                                                          // optionsProductset(
                                                          //   product,
                                                          //   itemIndex
                                                          // )
                                                          addtocartPress(
                                                            e,
                                                            product,
                                                            itemIndex,
                                                            packIndex
                                                          )
                                                        }
                                                      >
                                                        <h5 className="add_text">
                                                          <i
                                                            style={{
                                                              fontSize: "20px",
                                                            }}
                                                            className="fa-solid fa-plus"
                                                          />
                                                        </h5>
                                                        <i
                                                          style={{
                                                            fontSize: "16px",
                                                          }}
                                                          className="fa-solid fa-plus add_text1"
                                                        />
                                                      </button>

                                                    </>)}

                                                  </>
                                                ) : (
                                                  <>
                                                    {hotProducts[itemIndex]
                                                      .PackSizes[packIndex]
                                                      .CartQuantity > 0 ? (
                                                      <>
                                                        <div className="qty-box cart_qty open">
                                                          <div>
                                                            <div className="input-group">
                                                              <button
                                                                type="button"
                                                                style={{
                                                                  backgroundColor:
                                                                    "#00ab66",
                                                                }}
                                                                className="btn qty-left-minus"
                                                                data-type="minus"
                                                                data-field
                                                                onClick={(e) =>
                                                                  decrementtocartPress(
                                                                    e,
                                                                    product,
                                                                    itemIndex,
                                                                    packIndex
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-minus"
                                                                  aria-hidden="true"
                                                                  style={{
                                                                    color:
                                                                      "#fff",
                                                                  }}
                                                                />
                                                              </button>
                                                              <div
                                                                className="form-control input-number qty-input "
                                                                style={{
                                                                  backgroundColor:
                                                                    "#00ab66",
                                                                  border:
                                                                    "unset",
                                                                  paddingTop:
                                                                    "3px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#fff",
                                                                  }}
                                                                >
                                                                  {
                                                                    hotProducts[
                                                                      itemIndex
                                                                    ].PackSizes[
                                                                      packIndex
                                                                    ]
                                                                      .CartQuantity
                                                                  }
                                                                </span>
                                                              </div>

                                                              {11 ===
                                                                hotProducts[
                                                                  itemIndex
                                                                ].PackSizes[
                                                                  packIndex
                                                                ].CartQuantity ? (
                                                                <>
                                                                  <button
                                                                    type="button"
                                                                    className="btn qty-right-plus"
                                                                    data-type="plus"
                                                                    data-field
                                                                  >
                                                                    <i
                                                                      className="fa fa-plus"
                                                                      aria-hidden="true"
                                                                    />
                                                                  </button>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <button
                                                                    type="button"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#00ab66",
                                                                    }}
                                                                    className="btn qty-right-plus"
                                                                    data-type="plus"
                                                                    data-field
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      addtocartPress(
                                                                        e,
                                                                        product,
                                                                        itemIndex,
                                                                        packIndex
                                                                      )
                                                                    }
                                                                  >
                                                                    <i
                                                                      className="fa fa-plus"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        color:
                                                                          "#fff",
                                                                      }}
                                                                    />
                                                                  </button>
                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <button
                                                          className="add-button addcart-button btn buy-button text-light"
                                                          onClick={(e) =>
                                                            addtocartPress(
                                                              e,
                                                              product,
                                                              itemIndex,
                                                              packIndex
                                                            )
                                                          }
                                                        >
                                                          <h5 className="add_text">
                                                            <i
                                                              style={{
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                              className="fa-solid fa-plus"
                                                            />
                                                          </h5>
                                                          <i
                                                            style={{
                                                              fontSize: "16px",
                                                            }}
                                                            className="fa-solid fa-plus add_text1"
                                                          />
                                                        </button>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        {/* <div className="add-to-cart-box bg-white mt-2">
                                          {pack.OutOfStack === true ? (
                                            <>
                                              {" "}
                                              <button className="btn btn-add-cart addcart-button">
                                                <span className="add_text">
                                                  out of stock
                                                </span>
                                                <h4 className="add_text showoption">
                                                  out of stock
                                                </h4>
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {product.Multi === true ? (
                                                <>
                                                  <button
                                                    className="btn btn-add-cart addcart-button"
                                                    style={{
                                                      background: "#f8f9fa",
                                                    }}
                                                    onClick={() =>
                                                      optionsProductset(
                                                        product,
                                                        itemIndex
                                                      )
                                                    }
                                                  >
                                                    <span className="add_text">
                                                      Options
                                                    </span>
                                                    <h4 className="add_text showoption">
                                                      Options
                                                    </h4>
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  {hotProducts[itemIndex]
                                                    .cartPackquentity[packIndex]
                                                    .CartQuantity > 0 ? (
                                                    <>
                                                      <div className="cart_qty qty-box open">
                                                        <div
                                                          className="input-group bg-white"
                                                          // style={{ background: "#f8f9fa" }}
                                                        >
                                                          <button
                                                            type="button"
                                                            className="qty-left-minus bg-gray"
                                                            data-type="minus"
                                                            data-field
                                                            onClick={(e) =>
                                                              decrementtocartPress(
                                                                e,
                                                                product,
                                                                itemIndex,
                                                                packIndex
                                                              )
                                                            }
                                                            style={{
                                                              background: "red",
                                                            }}
                                                          >
                                                            <i
                                                              className="fa fa-minus"
                                                              aria-hidden="true"
                                                            />
                                                          </button>
                                                          <input
                                                            className="form-control input-number qty-input"
                                                            type="text"
                                                            name="quantity"
                                                            defaultValue={0}
                                                            value={
                                                              hotProducts[
                                                                itemIndex
                                                              ]
                                                                .cartPackquentity[
                                                                packIndex
                                                              ].CartQuantity
                                                            }
                                                            contentEditable="false"
                                                          />

                                                          {11 >=
                                                          hotProducts[itemIndex]
                                                            .cartPackquentity[
                                                            packIndex
                                                          ].CartQuantity ? (
                                                            <>
                                                              <button
                                                                type="button"
                                                                className="qty-right-plus bg-gray"
                                                                data-type="plus"
                                                                data-field
                                                                onClick={(e) =>
                                                                  addtocartPress(
                                                                    e,
                                                                    product,
                                                                    itemIndex,
                                                                    packIndex
                                                                  )
                                                                }
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                />
                                                              </button>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <button
                                                                type="button"
                                                                className="qty-right-plus bg-gray"
                                                                data-type="plus"
                                                                data-field
                                                              >
                                                                <i
                                                                  className="fa fa-plus"
                                                                  aria-hidden="true"
                                                                />
                                                              </button>
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        className="btn btn-add-cart addcart-button"
                                                        style={{
                                                          background: "#f8f9fa",
                                                        }}
                                                        onClick={(e) =>
                                                          addtocartPress(
                                                            e,
                                                            product,
                                                            itemIndex,
                                                            packIndex
                                                          )
                                                        }
                                                      >
                                                        <h5 className="add_text5">
                                                          Add
                                                        </h5>

                                                        <span
                                                          className="add-icon bg-light-gray"
                                                          style={{
                                                            background: "#fff",
                                                          }}
                                                        >
                                                          <i className="fa-solid fa-plus" />
                                                        </span>
                                                      </button>
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div> */}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div key={pack._id}></div>
                                    </>
                                  )
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </>
                  </Swiper>
                </div>
              </div>
            </div>
            <Modal
              size="md"
              // centered
              isOpen={visible}
              toggle={() => setVisible(!visible)}
              modalTransition={{ timeout: 500 }}
              className={window.innerWidth < 780 ? 'mobile-versionnnn-modal' : 'desktop-versionnnnnn-modal'}
            >
              <ModalBody>
                <h3>{currentProduct.ItemName}</h3>
                <h5>{currentProduct.Brand}</h5>
                <div className="col-12 mt-3 ">
                  {currentProduct &&
                    currentProduct.PackSizes.map((packsize, packIndex) => (
                      <div
                        className="col-12 d-flex justify-content-between my-2"
                        key={packsize._id}
                      >
                        <div className="col-2">
                          <img
                            src={packsize.ImgUrlDesk}
                            className="img-fluid blur-up lazyload"
                            alt="image"
                            width={200}
                          />
                        </div>
                        <h6
                          className="col-1 d-flex justify-content-center align-items-center "
                          style={{
                            color: "#000",
                            fontSize: "14px",
                            marginLeft: "2vh",
                            fontFamily: "Poppins",
                          }}
                        >
                          {packsize.PackSize}
                        </h6>
                        <h6
                          className="col-3 d-flex justify-content-center align-items-center "
                          style={{
                            color: "#000",
                            fontSize: "14px",
                            marginLeft: "2vh",
                            fontFamily: "Poppins",
                          }}
                        >
                          ₹{packsize.SellingPrice}
                          {packsize.Mrp === packsize.SellingPrice ? (
                            <> </>
                          ) : (
                            <>
                              <span
                                style={{
                                  color: "#ccc",
                                  fontSize: "12px",
                                  textDecorationLine: "line-through",
                                  marginLeft: "0.5vh",
                                }}
                              >
                                ₹{packsize.Mrp}
                              </span>
                            </>
                          )}
                        </h6>
                        <div className="col-3 d-flex justify-content-end align-items-center">
                          {packsize.OutOfStack === true ? (
                            <>
                              {" "}
                              <Button
                                variant="contained"
                                style={{
                                  color: "#fff",
                                  background: "#009900",
                                  // fontSize:"15px"
                                }}
                                className="mx-1"
                              >
                                out of stock
                              </Button>
                            </>
                          ) : (
                            <>
                              {currentProduct.PackSizes[packIndex]
                                .CartQuantity > 0 ? (
                                <>
                                  <div className="addtocart_btn">
                                    <div className="qty-box cart_qty open">
                                      <div className="input-group">
                                        <button
                                          type="button"
                                          className="btn qty-left-minus"
                                          data-type="minus"
                                          data-field
                                          onClick={(e) =>
                                            decrementtocartPress(
                                              e,
                                              currentProduct,
                                              curritemIndex,
                                              packIndex
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <input
                                          className="form-control input-number qty-input"
                                          type="text"
                                          name="quantity"
                                          defaultValue={0}
                                          value={
                                            currentProduct.PackSizes[packIndex]
                                              .CartQuantity
                                          }
                                          readonly="readonly"
                                        />
                                        {11 ===
                                          currentProduct.PackSizes[packIndex]
                                            .CartQuantity ? (
                                          <>
                                            <div className="qty-box cart_qty open">
                                              <div className="input-group"></div>
                                            </div>
                                            <button
                                              type="button"
                                              className="btn qty-left-plus"
                                              data-type="plus"
                                              data-field
                                            >
                                              <i
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              type="button"
                                              className="btn qty-left-plus"
                                              data-type="plus"
                                              data-field
                                              onClick={(e) =>
                                                addtocartPress(
                                                  e,
                                                  currentProduct,
                                                  curritemIndex,
                                                  packIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "#fff",
                                      background: "#009900",
                                    }}
                                    className="mx-1"
                                    onClick={(e) =>
                                      addtocartPress(
                                        e,
                                        currentProduct,
                                        curritemIndex,
                                        packIndex
                                      )
                                    }
                                  >
                                    Add
                                    <span className="mx-1">
                                      {/* <i className="fa-solid fa-plus" /> */}
                                    </span>
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </ModalBody>
            </Modal>
          </section>{" "}
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
    </>
  );
};

export default OrganicVegetable;
