import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";

const OrderSuccesCheckOut = () => {
  const { currentOrder } = useSelector((state) => state.Order);
  return (
    <>
      <div className="col-xxl-3 col-lg-4">
        <div className="row g-4">
          <div className="col-lg-12 col-sm-6">
            <div className="summery-box">
              <div className="summery-header">
                <h3 style={{color:"#000",fontFamily: "Poppins",fontWeight:"600"}}>Price Details</h3>
                <h5 className="ms-auto theme-color">
                  ({currentOrder.OrderProducts.length} Items)
                </h5>
              </div>
              <ul className="summery-contain">
                <li>
                  <h4 style={{fontWeight:"600"}}>Sub-Total</h4>
                  <h4 className="price" style={{color:"#000",fontFamily: "Poppins",fontWeight:"600"}}>
                    ₹
                    {currentOrder.TotalAmount +
                      currentOrder.couponDetails.couponDis -
                      currentOrder.DeliveryCharge}
                  </h4>
                </li>

                <li>
                  <h4>Shipping</h4>
                  <h4 className="price">₹{currentOrder.DeliveryCharge}</h4>
                </li>
                <li>
                  <h4 style={{fontWeight:"600"}}> Saving</h4>
                  <h4 className="price theme-color" style={{fontFamily: "Poppins",fontWeight:"600"}}>₹{currentOrder.Saving}</h4>
                </li>
                <li>
                  <h4>Coupon Discount</h4>
                  <h4 className="price text-danger">
                    ₹{currentOrder.couponDetails.couponDis}
                  </h4>
                </li>
              </ul>
              <ul className="summery-total">
                <li className="list-total">
                  <h4 style={{color:"#000",fontFamily: "Poppins",fontWeight:"500"}}>Total (INR)</h4>
                  <h4 className="price" style={{color:"#000",fontFamily: "Poppins",fontWeight:"600"}}>₹{currentOrder.TotalAmount}</h4>
                </li>
                <li className="list-total">
                  <h4>Wallet Used</h4>
                  <h4 className="price">₹{currentOrder.wallet}</h4>
                </li>
                <li className="list-total">
                  <h4 style={{color:"#000",fontFamily: "Poppins",fontWeight:"600"}}>Grand Total ()</h4>
                  <h4 className="price" style={{color:"#000",fontFamily: "Poppins",fontWeight:"600"}}>₹{currentOrder.GrandTotal}</h4>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-12 col-sm-6">
            <div className="summery-box">
              <div className="summery-header d-block">
                <h3>Shipping Address</h3>
              </div>
              <ul className="summery-contain pb-0 border-bottom-0">
                <li className="d-block">
                  <h4>{currentOrder.AreaName}</h4>
                  <h4 className="mt-2">{currentOrder.Address.slice(0, 30)},</h4>
                  <h4 className="mt-2">
                    {currentOrder.Address.slice(30, 60)},
                  </h4>
                  <h4 className="mt-2">{currentOrder.Address.slice(60, 90)}</h4>
                  <h4 className="mt-2">
                    {currentOrder.Address.slice(90, 120)}
                  </h4>
                </li>
                <li className="pb-0">
                  <h4>Expected Date Of Delivery:</h4>
                  <h4 className="price theme-color">
                    <Link to="/account">
                      <a className="text-danger" style={{fontFamily: "Poppins",fontWeight:"600"}}>Track Order</a>
                    </Link>
                  </h4>
                </li>
              </ul>
              <ul className="summery-total">
                <li className="list-total border-top-0 pt-2">
                  <h4 className="fw-bold">
                    {moment(currentOrder.ExpectedDelDate).format("DD-MMM-YYYY")}
                  </h4>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="summery-box">
              <div className="summery-header d-block">
                <h3>Payment Method</h3>
              </div>
              <ul className="summery-contain pb-0 border-bottom-0">
                <li className="d-block pt-0">
                  <p className="text-content">
                    Pay on Delivery (Cash/Card). Cash on delivery (COD)
                    available. Card/Net banking acceptance subject to device
                    availability.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccesCheckOut;
