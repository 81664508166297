import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginScreen from "./Login/LoginScreen";
import OtpScreen from "./Login/OtpScreen";

const Login = () => {
  const { pageStatus } = useSelector((state) => state.Athentication);

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <LoginScreen />;
      case 1:
        return <OtpScreen />;
      default:
        return <LoginScreen />;
        break;
    }
  };

  return (
    <>
   {renderSwitch(pageStatus)}
    </>
 )
};

export default Login;
