import React, { useEffect, useState, useRef } from "react";
import "./FillCart.css";
import {
  MdCheckCircleOutline,
  MdKeyboardArrowRight,
  MdLocationSearching,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { GoClockFill } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { LuMinus } from "react-icons/lu";
import { RiCoupon2Fill } from "react-icons/ri";
import { FaCheck, FaLongArrowAltRight, FaRegFileAlt } from "react-icons/fa";
import { GiScooter, GiTakeMyMoney } from "react-icons/gi";
import { BiMessageDots } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { Link, unstable_HistoryRouter, useNavigate } from "react-router-dom";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import { Navigation } from "swiper/modules";
import { Button, Drawer, Input, Radio, Space } from "antd";
import RightSideCheckOut2 from "../CheckOut/RightSideCheckout2";
import { CloseOutlined } from "@mui/icons-material";
import {
  authActions,
  clientRegistation,
  getClientByMob,
  loginAuthentication,
  LoginOtp,
  pageStatusChange,
  updateAddress,
} from "../../redux/athentication/Athentication";
import { getOrderbyClId } from "../../redux/order/OrderSlice";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const FillCart = () => {
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    cartTotalMrp,
    cartTotalPrice,
  } = useSelector((state) => state.Cart);

  const {
    isAuth,
    address,
    currentAddress,
    addressModelStatus,
    loginData,
    clientid,
    mob,
    otp,
  } = useSelector((state) => state.Athentication);

  console.log(isAuth, "isAuth");
  console.log(loginData, "loginData");
  console.log(currentAddress, "currentAddress");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const [cartamountDetails, setCartamountDetails] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [numberAdd, setNumberAdd] = useState("");
  const [altmobile, setAltMobile] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [addres, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [errormassage, setErrormassage] = useState("");
  const [pinerrormassage, setPinErrormassage] = useState("");
  const [landmark, setLandmark] = useState("");
  const [areaDept, setAreaDept] = useState("");
  const [clientIdAfRegister, setClientIdAfRegister] = useState("");
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const [openMobile, setOpenMobile] = useState(false);
  const [placementMobile, setPlacementMobile] = useState("bottom");
  const [openOtp, setOpenOtp] = useState(false);
  const [placementOtp, setPlacementOtp] = useState("bottom");
  const [openAddress, setOpenAddress] = useState(false);
  const [number, setNumber] = useState("");
  const [mainerrormassage, setMainErrormassage] = useState("");
  const [placementAddress, setPlacementAddress] = useState("bottom");
  const [showResults, setShowResults] = useState(false);

  const [errormassageEmail, setErrormassageEmail] = useState("");
  const [uniqueemail, setUniqueEmail] = useState("");

  const [regError, setRegError] = useState("");
  const [submiterror, setSubmiterror] = useState("red");

  useEffect(() => {
    if (loginData) {
      setName(loginData.Name);
      setEmail(loginData.Email);
      setNumberAdd(loginData.Mobile);
    }
  }, []);

  useEffect(() => {
    if (mob) {
      setNumberAdd(mob);
    }
  }, [mob]);

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: {
      AName: "",
      City: "",
      Number: "",
      Mobile: "",
      Pincode: "",
      State: "",
      HNo: "",
      StreetDet: "",
      AreaDet: "",
      LandMark: "",
      Address: "",
    },
    error: null,
  });
  // for otp
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const handleAltMobileNumber = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setAltMobile(value);
    }
  };

  const removefromcart = (cartItem) => {
    dispatch(removefromCart(cartItem));
    dispatch(getCartTotal());
  };
  const handledecreasequtcart = (cartItem) => {
    dispatch(decrementCart(cartItem));
    dispatch(getCartTotal());
  };
  const handleincreasequtcart = (cartItem) => {
    dispatch(addtoCart(cartItem));
    dispatch(getCartTotal());
  };
  useEffect(() => {
    let cartTotalAmountN = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN = cartTotalAmountN + CartItems[i].TotalAmount;
      cartTotalMrpN = cartTotalMrpN + CartItems[i].TotalMrp;
      cartTotalPriceN = cartTotalPriceN + CartItems[i].TotalPrice;
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    netPayableN = cartTotalAmountN + logisticsAmountN;

    const cartAmountsForm = {
      cartTotalAmount: cartTotalAmountN,
      netPayable: netPayableN,
      cartTotalMrp: cartTotalMrpN,
      cartTotalPrice: cartTotalPriceN,
      logisticsAmount: logisticsAmountN,
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  //new drawer
  const showDrawer = () => {
    setOpen(true);
    console.log("draweeeeerrr......");
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };
  const showDrawerMobile = () => {
    setOpenMobile(true);
  };
  const onChangeMobile = (e) => {
    setPlacementMobile(e.target.value);
  };
  const onCloseMobile = () => {
    setOpenMobile(false);
    setNumber("");
  };
  const showDrawerAddress = () => {
    setOpenAddress(true);
  };
  const onCloseAddress = () => {
    setOpenAddress(false);
  };
  const onChangeAddress = (e) => {
    setPlacementAddress(e.target.value);
  };
  const showDrawerOtp = () => {
    setOpenOtp(true);
  };
  const onCloseOtp = () => {
    setOpenOtp(false);
  };
  const onChangeOtp = (e) => {
    setPlacementOtp(e.target.value);
  };

  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  // useEffect(() => {
  //   document.getElementById("first").focus();
  // }, []);

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    // setErrormassage("");
    setNumber(numbers);
  };
  const handleChangePinocode = (e) => {
    const value = e.target.value;

    if (/^\d{0,6}$/.test(value)) {
      setPinCodecon(value);
    }
  };

  const handleChangeCity = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z]*$/.test(value)) {
      setCity(value);
    }

  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8018582135") {
        OTP = "111444";
      }
      dispatch(LoginOtp({ number, OTP }));
      const formdata = {
        number: number,
        OTP: OTP,
      };
      dispatch(loginAuthentication(formdata));
      setOpenOtp(true);
      setOpenMobile(false);
      setNumber("");
      setOtp1("");
      setOtp2("");
      setOtp3("");
      setOtp4("");
      setOtp5("");
      setOtp6("");
    } else {
      // setErrormassage(" Please enter valid number");
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(pageStatusChange(0));
  }, [CartItems, dispatch]);

  const otpchange1 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtp1(value);
      document.getElementById("second").focus();
    } else if (value === "") {
      setOtp1("");
    }
  };
  const otpchange2 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtp2(value);
      document.getElementById("third").focus();
    } else if (value === "") {
      document.getElementById("first").focus();
      setOtp2("");
    }
  };
  const otpchange3 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtp3(value);
      document.getElementById("fourth").focus();
    } else if (value === "") {
      document.getElementById("second").focus();
      setOtp3("");
    }
  };
  const otpchange4 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtp4(value);
      document.getElementById("fifth").focus();
    } else if (value === "") {
      document.getElementById("third").focus();
      setOtp4("");
    }
  };
  const otpchange5 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtp5(value);
      document.getElementById("sixth").focus();
    } else if (value === "") {
      document.getElementById("fourth").focus();
      setOtp5("");
    }
  };
  const otpchange6 = (e) => {
    let value = e.target.value;

    if (value != "" && value >= 0) {
      value = Number(value) % 10;
      setOtp6(value);
      document.getElementById("btnsubmit").focus();
    } else if (value === "") {
      document.getElementById("fifth").focus();
      setOtp6("");
    }
  };

  const verifyClick = async () => {
    let otpvalue = String(otp1) + otp2 + otp3 + otp4 + otp5 + otp6;
    if (otpvalue.length === 6) {
      // setLogerrorcolor("green");
      // setLogerror("OTP verifying");

      if (otpvalue === otp) {
        const formData = {
          Mobile: mob,
        };
        const ClientDetails = await dispatch(getClientByMob(formData));
        if (ClientDetails.payload.success && address.length > 0) {
          setOpenOtp(false);
          setOpenAddress(false);
        } else if (ClientDetails.payload.success && address.length === 0) {
          setOpenAddress(true);
          setOpenOtp(false);
        }
        if (ClientDetails.payload.success === false) {
          setOpenAddress(true);
          setOpenOtp(false);
        } else {
          dispatch(
            authActions.signin({
              // token: ClientDetails.token,
              ...ClientDetails.payload.client,
              isAuth: true,
            })
          );
          const client = ClientDetails.payload.client;
          dispatch(getOrderbyClId(client._id));
          // navigate("/");
          // dispatch(pageStatusChange(0));
        }
      } else {
        // setLogerrorcolor("red");
        // setLogerror("please enter correct OTP");
      }
    }
  };

  const emailchange = (e) => {
    const value = e.target.value;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setErrormassageEmail("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        if (value != "") {
          setUniqueEmail(value);
        }
      } else {
        setErrormassageEmail("please enter correct Email format");
      }
    } else {
      setErrormassageEmail("please enter correct Email format");
    }
  };

  const onSubmitHanlderAddresss = async (e) => {
    e.preventDefault();
    setMainErrormassage("");

    const isFormValid = (name, mobile, pincodecon, city) =>
      name !== "" && mobile !== "" && pincodecon !== "" && city !== "";

    const formatName = (name) =>
      name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

    const submitAddress = async (clientId) => {
      const formData = {
        AName: formatName(name),
        Number: numberAdd,
        Mobile: altmobile,
        Pincode: pincodecon,
        State: state,
        City: city,
        HNo: houseNo,
        StreetDet: street,
        AreaDet: areaDept,
        LandMark: landmark,
        Address: addres,
        Type: addressType,
        ClientId: clientId,
      };

      const config = {
        headers: { "Content-Type": "application/json" },
      };

      try {
        const url = `${Baseurl}/api/v1/client/address`;
        const addAddress = await axios.put(url, formData, config);

        if (addAddress.data.success) {
          const lastAddress = addAddress.data.client;
          const currentAddress = [...lastAddress.Addresses];
          setOpenAddress(false);
          navigate("/checkout");
          dispatch(updateAddress(currentAddress));
          dispatch(addressModelStatus(false));
        }
      } catch (error) {
        console.error("Error adding address:", error);
        setMainErrormassage("Failed to add address. Please try again.");
      }
    };

    // When authenticated and the current address is empty
    if (isAuth && currentAddress === "") {
      if (isFormValid(name, mobile, pincodecon, city)) {
        await submitAddress(clientid);
      } else {
        setMainErrormassage("Please enter correct values");
      }
    } else {
      if (name !== "" && uniqueemail !== "") {
        const formData = {
          Name: formatName(name),
          Email: uniqueemail,
          Mobile: mob,
        };

        try {
          const ClientDetails = await dispatch(clientRegistation(formData));

          if (ClientDetails.payload.success) {
            dispatch(
              authActions.signin({
                ...ClientDetails.payload.client,
                isAuth: true,
              })
            );

            const clientIdAfRegister = ClientDetails.payload.client._id;
            setClientIdAfRegister(clientIdAfRegister);

            if (isFormValid(name, mobile, pincodecon, city)) {
              await submitAddress(clientIdAfRegister);
            }
          }
        } catch (error) {
          console.error("Error during client registration:", error);
        }
      } else {
        setMainErrormassage("Please enter correct values");
      }
    }
  };

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems, dispatch]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude);
          setLocation((prevState) => ({
            ...prevState,
            latitude,
            longitude,
            error: null,
          }));

          fetchAddress(latitude, longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert("Please allow location access in your browser settings.");
          }
          console.log("1");
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        },
        { enableHighAccuracy: true }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  };
  const fetchAddress = async (latitude, longitude) => {
    const apiKey = "AIzaSyAkOSe3Ns4VZ9MUqztuZj49GrUQ3mMqMag";
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const singleAddress = response.data.results[0].address_components;
      // console.log(response.data.results[0].formatted_address, "response.data.results[0]");
      // console.log(singleAddress,"singleAddress34567");
      const address = {
        AName: singleAddress[3].long_name,
        City: singleAddress[6].long_name,
        Pincode: singleAddress[9].long_name,
        State: singleAddress[7].long_name,
        HNo: singleAddress[0].long_name,
        StreetDet: singleAddress[1].long_name,
        AreaDet: singleAddress[2].long_name,
        LandMark: singleAddress[3].long_name,
        Address: response.data.results[0].formatted_address,
      };
      // console.log(address, "formData123");

      setLocation((prevState) => ({
        ...prevState,
        address,
      }));
    } catch (error) {
      setLocation((prevState) => ({
        ...prevState,
        address: "Unable to fetch address",
        error: error.message,
      }));
    }

    // setAddress
  };

  useEffect(() => {
    setCity(location.address.City);
    setNumber(location.address.Number);
    setMobile(location.address.Mobile);
    setPinCodecon(location.address.Pincode);
    setState(location.address.State);
    setHouseNo(location.address.HNo);
    setStreet(location.address.StreetDet);
    setAreaDept(location.address.AreaDet);
    setLandmark(location.address.LandMark);
    setAddress(location.address.Address);
  }, [location]);
  return (
    <div>
      <div className="MainPartOfCartPage">
        <div className="NavofCartpage">
          <div className="navpart1">
            <span className="iconn" onClick={() => window.history.back()}>
              <MdOutlineArrowBackIos className="iconnn" />
            </span>
            <span className="yourcarttextt">Your Cart</span>
            <span className="savedmoney">
              SAVED <b id="boldtexttt">₹{cartTotalMrp - cartTotalPrice}</b>
            </span>
          </div>
          {/* <div className="navpart2">
            <span>
              <MdCheckCircleOutline className="circleIconn" />
              <b className="colorchange">
                Saved ₹{cartTotalMrp - cartTotalPrice}
              </b>
              including <b>₹{299 - cartTotalPrice} </b> through free delivery!
            </span>
          </div> */}

          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="saved_money_con">
              {/* <div className="for_free_deliveryyyy">
              <span className="for_free_deliveryyyy_text">FREE DELIVERY</span>
            </div> */}
              {cartTotalPrice >= 299 ? (
                <>
                  <div>
                    <span className="to_unlock_freee">
                      You Have unlocked FREE DELIVERY on this order
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span className="add_itemsssssss">
                      Add items Worth
                      <span className="bold_selling_priceeee">
                        ₹{299 - cartTotalPrice}{" "}
                      </span>{" "}
                      to unlock FREE DELIVERY
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

        </div>
        <div className="ContainerPartofCartPage">
          <div className="container1">
            {/* <div className="Containerpart1">
              <span className="clockContainer">
                <GoClockFill className="clockIconn" />
              </span>
              <span className="deliveryText">Your order is on the way!</span>
            </div> */}
            {CartItems &&
              CartItems.map((cart, index) => (
                <div className="Containerpart2" key={index}>
                  <div className="imageAndName">
                    <div className="imgContainer">
                      <img src={cart.ImgUrl} alt="" className="imagee" />
                    </div>
                    <div className="aboutImgText" style={{ marginTop: "10px" }}>
                      <h6 style={{ fontFamily: "Poppins", color: "#000" }}>
                        {cart.ItemName}
                      </h6>
                      <p
                        style={{
                          paddingTop: "6px",
                          color: "#ccc",
                          fontFamily: "Poppins",
                        }}
                      >
                        {cart.PackSize}
                      </p>
                    </div>
                  </div>
                
                  <div className="addorRemove">
                    <span
                      className="symb"
                      onClick={() => handledecreasequtcart(cart)}
                    >
                      <LuMinus id="coloring" />
                    </span>
                    <span style={{ color: "#fff" }}>{cart.Qty}</span>
                    <span
                      className="symb"
                      onClick={() => handleincreasequtcart(cart)}
                    >
                      <IoMdAdd id="coloring" />
                    </span>
                  </div>
                  <div className="Pricepart" style={{ marginLeft: "10px" }}>
                    <h6
                      style={{
                        paddingTop: "9px",
                        color: "#000",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      ₹{cart.Price}
                    </h6>
                    <p
                      style={{
                        paddingTop: "9px",
                        color: "#ccc",
                        fontFamily: "Poppins",
                      }}
                    >
                      ₹{cart.Mrp}
                    </p>
                  </div>
                </div>
              ))}

            {/*  */}
            <div className="Containerpart3">
              <div className="dottedline"> </div>
              <div className="textContainerrr">
                <span className="missText">Missed something?</span>
                <Link to="/">
                  <div className="addmorebtn">
                    <span>
                      <IoMdAdd className="addIconnn" /> Add More Items
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="container2">
            <div className="cont2part1">
              <h6>₹{cartTotalMrp - cartTotalPrice}</h6>
              <span>saved with</span>
              <RiCoupon2Fill className="passIcon" />
            </div>
            <div className="cont2part2">
              <span>
                <span className="goldcoloring">
                  <FaCheck />
                </span>
                <b>Free delivery</b>savings of{" "}
                <b className="goldcoloring">₹{299 - cartTotalPrice}</b>
              </span>
            </div>
            <div className="cont2part2">
              <span>
                <span className="goldcoloring">
                  <FaCheck />
                </span>
                <b>Extra discount</b>above ₹299
                {/* <b className="goldcoloring">₹12</b> */}
              </span>
            </div>
          </div>
          <div className="container3">
            {/* <div className="cont3subPart">
              <div className="moneyIconAndTipText">
                <span>
                  <GiTakeMyMoney />
                </span>
                <div className="tipText">
                  <span>
                    <b>Dilivery Partner Tip</b>
                  </span>
                  <span>This amount goes to your delivery partner</span>
                </div>
              </div>
              <span>
                <MdKeyboardArrowRight className="ArrowIcon" />
              </span>
            </div>
            <div className="cont3subPart">
              <div className="moneyIconAndTipText">
                <span>
                  <BiMessageDots />
                </span>
                <div className="tipText">
                  <span>
                    <b>Dilivery Instructions</b>
                  </span>
                  <span>Delivery partner will be notified</span>
                </div>
              </div>
              <span>
                <MdKeyboardArrowRight className="ArrowIcon" />
              </span>
            </div> */}
            <div className="cont3subPart" onClick={showDrawer}>
              <div className="moneyIconAndTipText">
                <span>
                  <FaRegFileAlt />
                </span>
                <div className="tipText">
                  <span>
                    <b>To Pay</b>
                  </span>
                  <span>Incl.all texes and charges</span>
                </div>
              </div>
              <div className="Savinggs">
                <div className="priceeee">
                  <span>
                    <del>₹{cartamountDetails.cartTotalMrp}</del>
                    &nbsp; <b>₹{cartamountDetails.cartTotalAmount}</b>
                  </span>
                  <span className="savingmoneyyy">
                    saving ₹{cartTotalMrp - cartTotalPrice}
                  </span>
                </div>
                <span>
                  <MdKeyboardArrowRight className="ArrowIcon" />
                </span>
              </div>
            </div>
            {/* <div className="cont3subPartt">
              <div className="moneyIconAndTipText">
                <span>
                  <GiScooter />
                </span>
                <div className="tipText">
                  <span>
                    <b>Dilivery Partner's Safety </b>
                  </span>
                  <span>Learn more about we ensure their safety</span>
                </div>
              </div>
              <span>
                <MdKeyboardArrowRight className="ArrowIcon" />
              </span>
            </div> */}
            <div className="maindrawer">
              <Space className="d-hidden">
                <Radio.Group
                  value={placement}
                  className="d-none"
                  onChange={onChange}
                >
                  <Radio value="bottom">bottom</Radio>
                </Radio.Group>
              </Space>
              <Drawer
                placement={placement}
                width={"100%"}
                onClose={onClose}
                height={370}
                open={open}
                style={{
                  paddingLeft:'15px',
                  paddingRight:'15px'
                }}
              >
                <RightSideCheckOut2 />
              </Drawer>
            </div>
          </div>
          {/*  */}
          <div className="conatiner333"></div>
        </div>
        {isAuth === true ? (
          <>
            {address.length > 0 ? (
              <>
                <div className="footerPartofCartPage">
                  <Link to="/checkout">
                    <div className="btnContainerr">
                      <button style={{ color: "#fff", fontFamily: "Poppins" }}>
                        Proceed to Checkout
                      </button>
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="footerPartofCartPage">
                  <Link to="">
                    <div
                      className="btnContainerr"
                      onClick={showDrawerAddress}
                    // onClick={() => dispatch(addressModelStatus(true))}
                    >
                      <button style={{ color: "#fff", fontFamily: "Poppins" }}>
                        Proceed to Checkout
                      </button>
                    </div>
                  </Link>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="footerPartofCartPage">
              <Link>
                <div className="btnContainerr" onClick={showDrawerMobile}>
                  <button style={{ color: "#fff", fontFamily: "Poppins" }}>
                    Proceed to Checkout
                  </button>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
      {/* This Code for the Mobile NUMBER */}
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "1.2rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>Enter Your Mobile Number</span>
            <span style={{ cursor: "pointer" }} onClick={onCloseMobile}>
              <CloseOutlined />
            </span>
          </div>
        }
        placement={placementMobile}
        closable={false}
        onClose={onCloseMobile}
        open={openMobile}
        key={placementMobile}
        height={200}
        drawerStyle={{
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
          // height: '200px'
        }}
        bodyStyle={{ padding: "16px" }}
      >
        <div className="center_container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "2px solid #22B6A8",
              padding: "8px",
              borderRadius: "8px",
              width: "80%",
            }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
              alt="Indian Flag"
              style={{ width: "30px", height: "20px", marginRight: "10px" }}
            />
            <span style={{ marginRight: "10px" }}>+91</span>
            <Input
              placeholder="Enter your mobile number"
              bordered={false}
              style={{ flex: 1 }}
              type="tel"
              inputMode="numeric"
              id="text"
              value={number}
              minLength={10}
              maxLength={10}
              pattern="[6789][0-9]{9}"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="verified_cardddd" onClick={(e) => onSubmitHandler(e)}>
            <button className="verify_btn">
              <span>Verify</span>
              <span>
                <FaLongArrowAltRight />
              </span>
            </button>
          </div>
        </div>
      </Drawer>
      {/* This code for the Otp number */}
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "1.2rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>Enter OTP Number</span>
            <span style={{ cursor: "pointer" }} onClick={onCloseOtp}>
              <CloseOutlined />
            </span>
          </div>
        }
        placement={placementOtp}
        closable={false}
        onClose={onCloseOtp}
        open={openOtp}
        key={placementOtp}
        height={200}
        drawerStyle={{
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
          height: "200px",
        }}
        bodyStyle={{ padding: "16px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="total_otp">
            <div className="constsiner_sty">
              <Input
                type="tel"
                id="first"
                inputMode="numeric"
                placeholder={0}
                pattern="[0-9]*"
                value={otp1}
                onChange={(e) => otpchange1(e)}
                className="inputStylesssss"
                maxLength={1}
              />
              <Input
                type="tel"
                id="second"
                inputMode="numeric"
                placeholder={0}
                pattern="[0-9]*"
                value={otp2}
                onChange={(e) => otpchange2(e)}
                className="inputStylesssss"
                // style={inputStyle}
                maxLength={1}
              />
              <Input
                type="tel"
                id="third"
                inputMode="numeric"
                placeholder={0}
                pattern="[0-9]*"
                value={otp3}
                onChange={(e) => otpchange3(e)}
                className="inputStylesssss"
                maxLength={1}
              />
              <Input
                type="tel"
                id="fourth"
                inputMode="numeric"
                placeholder={0}
                pattern="[0-9]*"
                value={otp4}
                onChange={(e) => otpchange4(e)}
                className="inputStylesssss"
                maxLength={1}
              />
              <Input
                type="tel"
                id="fifth"
                inputMode="numeric"
                placeholder={0}
                pattern="[0-9]*"
                value={otp5}
                onChange={(e) => otpchange5(e)}
                className="inputStylesssss"
                maxLength={1}
              />
              <Input
                type="tel"
                id="sixth"
                inputMode="numeric"
                placeholder={0}
                pattern="[0-9]*"
                value={otp6}
                onChange={(e) => otpchange6(e)}
                className="inputStylesssss"
                maxLength={1}
              />
            </div>
          </div>
        </div>
        <div className="verified_cardddd" onClick={() => verifyClick()}>
          <button className="verify_btn" id="btnsubmit">
            <span>Verify</span>
            <span>
              <FaLongArrowAltRight />
            </span>
          </button>
        </div>
      </Drawer>

      {/* for address drawer */}
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "1.2rem",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>Add new address</span>
            <span style={{ cursor: "pointer" }}>
              <CloseOutlined onClick={onCloseAddress} />
            </span>
          </div>
        }
        placement={placementAddress}
        closable={false}
        onClose={onCloseAddress}
        open={openAddress}
        key={placementAddress}
        height={550}
        drawerStyle={{
          borderTopRightRadius: "16px",
          borderTopLeftRadius: "16px",
        }}
        bodyStyle={{ padding: "16px" }}
      >
        <div>
          <div className="colorforthat">
            <p className="bold_text1">
              Use <span className="weight_bold_text1">"KKG12"</span> to Get
              Extra <span className="weight_bold_text1">12 % 0FF</span>
            </p>
          </div>

          <div>
            <div>
              <div class="personal-details-container">
                <div class="container-header">
                  <p className="for_personal">Personal Details</p>
                </div>
                <div style={{ textAlign: "end", fontSize: "1rem" }}>
                  <span
                    style={{
                      height: "30px",
                      width: "30px",
                      border: "1px solid #000",
                      textAlign: "center",
                      borderRadius: "50%",
                      borderColor: "#cccc",
                    }}
                    onClick={() => getLocation()}
                  >
                    <MdLocationSearching />
                  </span>
                </div>
                <div className="for_labels">
                  <span className="phone_nummmm">Phone Number</span>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="mobile_number_Containe">
                  <div className="flag_Connn">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                      alt="India Flag"
                      className="flgicom"
                    />
                    <span className="down_Arroe">▼</span>
                  </div>

                  <div className="phone_numberrrr_input_con">
                    <span style={{ marginRight: "5px" }}>+91</span>
                    <input
                      type="tel"
                      name="mobile"
                      id="mobile"
                      maxLength={10}
                      minLength={10}
                      required
                      className="phone_numberrrr_input"
                      value={numberAdd}
                      readOnly
                      onChange={(e) => setNumberAdd(e.target.value)}
                    />
                  </div>

                  <div style={{ marginLeft: "10px" }}>
                    <FaCheckCircle className="style_for_check" />
                  </div>
                </div>
                <div className="for_flex_con">
                  <div>
                    <input type="checkbox" id="checkbox" />
                  </div>
                  <div>
                    <p className="for_keepsss">
                      Keep me Posted about Sales and Offers
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <div>
                      <div className="for_labels">
                        <span className="phone_nummmm">Name</span>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <div className="input-containerrrrd_loginnn">
                          <input
                            type="text"
                            id="name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />

                          {isAuth ? (
                            <>
                              <div style={{ marginLeft: "10px" }}>
                                <FaCheckCircle className="style_for_check" />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="for_labels">
                        <span className="phone_nummmm">Email</span>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <div className="input-containerrrrd_loginnn">
                          <input
                            type="text"
                            id="name"
                            required
                            value={email}
                            onChange={(e) => emailchange(e)}
                          />

                          {isAuth ? (
                            <>
                              <div style={{ marginLeft: "10px" }}>
                                <FaCheckCircle className="style_for_check" />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <div>
                      <div className="for_labels">
                        <span className="phone_nummmm">Full Name</span>
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <div className="input-containerrrrd">
                          <input
                            type="text"
                            id="name"
                            required
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <div>
                      <div className="for_labels">
                        <span className="phone_nummmm">
                          Alternative Mobile Number
                        </span>
                      </div>
                      <div>
                        <div className="input-containerrrrd">
                          <input
                            type="tel"
                            name="mobile"
                            id="mobile"
                            minLength={10}
                            maxLength={10}
                            value={altmobile}
                            pattern="\d*"
                            onChange={(e) => handleAltMobileNumber(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="personal-details-containersss mt-2">
                <div class="container-headersss">
                  <p className="for_personal">Address</p>
                </div>
                <div className="for_flexss_container">
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">House No</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="name"
                          value={houseNo}
                          required
                          onChange={(e) => setHouseNo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">Street</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="name"
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="for_labels">
                    <span className="phone_nummmm">Address</span>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="textarea-containerrr">
                    <textarea
                      id="description"
                      // placeholder="Enter your description here..."
                      rows="3"
                      value={addres}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="for_flexss_container">
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">Land Mark</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="landmark"
                          value={landmark}
                          onChange={(e) => setLandmark(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">Area</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="area"
                          value={areaDept}
                          onChange={(e) => setAreaDept(e.target.value)}
                          // value={area}
                          // onChange={(e) => setArea(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="for_flexss_container">
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">State</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="state"
                          value={state}
                          required
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="for_flexss_container">
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">PinCode</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="pincode"
                          maxLength={6}
                          minLength={6}
                          value={pincodecon}
                          onChange={handleChangePinocode}

                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="for_labels">
                      <span className="phone_nummmm">City</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div>
                      <div className="input-containerrrrd">
                        <input
                          type="text"
                          id="name"
                          value={city}
                          required
                          onChange={handleChangeCity}
                        // onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="saved_Address_details">
                <div className="sub_Saved_Add">
                  <p className="save_text">Save Address as</p>
                </div>
                <div className="sub_content_save">
                  <div className="home_content">
                    {/* <span className="click_icon">
                      <FaCheck />
                    </span> */}
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="Home"
                      checked={addressType === "Home"}
                      onChange={(e) => {
                        setAddressType("Home");
                      }}
                    />
                    <span className="addres_De">Home</span>
                  </div>
                  <div className="home_content_border">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="Office"
                      checked={addressType === "Office"}
                      onChange={(e) => {
                        setAddressType("Office");
                      }}
                    />
                    <span className="addres_De">Office</span>
                  </div>
                  <div className="home_content_border">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      value="Others"
                      checked={addressType === "Others"}
                      onChange={(e) => {
                        setAddressType("Others");
                      }}
                    />
                    <span className="addres_De">Other</span>
                  </div>
                </div>
                <div className="chec_address">
                  {/* <div>
                    <input type="checkBox" name="" id="" />
                  </div>
                  <div>
                    <p>Save this address across Shopflo Checkouts</p>
                  </div> */}
                </div>

                <div className="save_and_Continuous_btn">
                  <button
                    className="for_sabe"
                    onClick={(e) => onSubmitHanlderAddresss(e)}
                  >
                    Save and Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default FillCart;
