import React, { useState,useEffect } from "react";
// import HotProductSlider from " ./src/pages/Home/Hotproductdetails/HotProductSlider";
import HotProductSlider from "./Hotproductdetails/HotProductSlider";
import Description from "./Hotproductdetails/Description";

const HotProductDetails = () => {
    const [showButton, setShowButton] = useState(false);
    
   

  return (
    <>
      <div>
        <section className="product-section">
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
                <div className="row g-4">
                  <div className="col-xl-6 wow fadeInUp">
                    <div className="product-left-box">
                      <div className="row g-2">
                        <div className="col-xxl-10 col-lg-12 col-md-10 order-xxl-2 order-lg-1 order-md-2">
                          <div className="product-main-2 no-arrow">
                            <div>
                              <div className="slider-image">
                                <img
                                  src="../assets/images/product/category/1.jpg"
                                  id="img-1"
                                  data-zoom-image="../assets/images/product/category/1.jpg"
                                  className="img-fluid image_zoom_cls-0 blur-up lazyload"
                                  alt
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="right-box-contain">
                      <h6 className="offer-top">30% Off</h6>
                      <h2 className="name">Creamy Chocolate Cake</h2>
                      <div className="price-rating">
                        <h3 className="theme-color price">
                          $49.50 <del className="text-content">$58.46</del>{" "}
                          <span className="offer theme-color">(8% off)</span>
                        </h3>
                        <div className="product-rating custom-rate">
                          <ul className="rating">
                            <li>
                              <i data-feather="star" className="fill" style={{color:"gold"}} />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                          </ul>
                          <span className="review">23 Customer Review</span>
                        </div>
                      </div>
                      <div className="procuct-contain">
                        <p>
                          Lollipop cake chocolate chocolate cake dessert
                          jujubes. Shortbread sugar plum dessert powder cookie
                          sweet brownie. Cake cookie apple pie dessert sugar
                          plum muffin cheesecake.
                        </p>
                      </div>
                      <div className="product-packege">
                        <div className="product-title">
                          <h4>Weight</h4>
                        </div>
                        <ul className="select-packege">
                          <li>
                            <a href="javascript:void(0)" className="active">
                              1/2 KG
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">1 KG</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">1.5 KG</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">Red Roses</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">With Pink Roses</a>
                          </li>
                        </ul>
                      </div>
                      {/* <div
                        className="time deal-timer product-deal-timer mx-md-0 mx-auto"
                        id="clockdiv-1"
                        data-hours={1}
                        data-minutes={2}
                        data-seconds={3}
                      >
                        <div className="product-title">
                          <h4>Hurry up! Sales Ends In</h4>
                        </div>
                        <ul>
                          <li>
                            <div className="counter d-block">
                              <div className="days d-block">
                                <h5 />
                              </div>
                              <h6>Days</h6>
                            </div>
                          </li>
                          <li>
                            <div className="counter d-block">
                              <div className="hours d-block">
                                <h5 />
                              </div>
                              <h6>Hours</h6>
                            </div>
                          </li>
                          <li>
                            <div className="counter d-block">
                              <div className="minutes d-block">
                                <h5 />
                              </div>
                              <h6>Min</h6>
                            </div>
                          </li>
                          <li>
                            <div className="counter d-block">
                              <div className="seconds d-block">
                                <h5 />
                              </div>
                              <h6>Sec</h6>
                            </div>
                          </li>
                        </ul>
                      </div> */}
                      <div className="note-box product-packege">
                        {showButton === false ? (
                          <>
                            <button
                              onclick={()=>setShowButton(showButton)}
                              className="btn btn-md bg-dark cart-button text-white w-100"
                            >
                              Add To Cart
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="cart_qty qty-box product-qty">
                              <div className="input-group">
                                <button
                                  type="button"
                                  className="qty-right-plus"
                                  data-type="plus"
                                  data-field
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-left-minus"
                                  data-type="minus"
                                  data-field
                                //   onclick={()=>setShowButton(showButton)}
                                >
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="buy-box">
                        <a href="wishlist.html">
                          <i data-feather="heart" />
                          <span>Add To Wishlist</span>
                        </a>
                        <a href="compare.html">
                          <i data-feather="shuffle" />
                          <span>Add To Compare</span>
                        </a>
                      </div>
                      <div className="pickup-box">
                        <div className="product-title">
                          <h4>Store Information</h4>
                        </div>
                        <div className="pickup-detail">
                          <h4 className="text-content">
                            Lollipop cake chocolate chocolate cake dessert
                            jujubes. Shortbread sugar plum dessert powder cookie
                            sweet brownie.
                          </h4>
                        </div>
                      </div>
                      <div className="paymnet-option">
                        <div className="product-title">
                          <h4>Guaranteed Safe Checkout</h4>
                        </div>
                        <ul>
                          <li>
                            <a href="javascript:void(0)">
                              <img
                                src="https://themes.pixelstrap.com/fastkart/assets/images/product/payment/1.svg"
                                className="blur-up lazyload"
                                alt
                              />
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">
                              <img
                                src="https://themes.pixelstrap.com/fastkart/assets/images/product/payment/2.svg"
                                className="blur-up lazyload"
                                alt
                              />
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">
                              <img
                                src="https://themes.pixelstrap.com/fastkart/assets/images/product/payment/3.svg"
                                className="blur-up lazyload"
                                alt
                              />
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">
                              <img
                                src="https://themes.pixelstrap.com/fastkart/assets/images/product/payment/4.svg"
                                className="blur-up lazyload"
                                alt
                              />
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">
                              <img
                                src="https://themes.pixelstrap.com/fastkart/assets/images/product/payment/5.svg"
                                className="blur-up lazyload"
                                alt
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <Description />
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
                <div className="right-sidebar-box">
                  <div className="pt-25">
                    <div className="category-menu">
                      <h3>Trending Products</h3>
                      {/* <ul className="product-list product-right-sidebar border-0 p-0">
              <li>
                <div className="offer-product">
                  <a href="product-left-thumbnail.html" className="offer-image">
                    <img
                      src="../assets/images/vegetable/product/23.png"
                      className="img-fluid blur-up lazyload"
                      alt
                    />
                  </a>
                  <div className="offer-detail">
                    <div>
                      <a href="product-left-thumbnail.html">
                        <h6 className="name">Meatigo Premium Goat Curry</h6>
                      </a>
                      <span>450 G</span>
                      <h6 className="price theme-color">$ 70.00</h6>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="offer-product">
                  <a href="product-left-thumbnail.html" className="offer-image">
                    <img
                      src="../assets/images/vegetable/product/24.png"
                      className="blur-up lazyload"
                      alt
                    />
                  </a>
                  <div className="offer-detail">
                    <div>
                      <a href="product-left-thumbnail.html">
                        <h6 className="name">Dates Medjoul Premium Imported</h6>
                      </a>
                      <span>450 G</span>
                      <h6 className="price theme-color">$ 40.00</h6>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="offer-product">
                  <a href="product-left-thumbnail.html" className="offer-image">
                    <img
                      src="../assets/images/vegetable/product/25.png"
                      className="blur-up lazyload"
                      alt
                    />
                  </a>
                  <div className="offer-detail">
                    <div>
                      <a href="product-left-thumbnail.html">
                        <h6 className="name">Good Life Walnut Kernels</h6>
                      </a>
                      <span>200 G</span>
                      <h6 className="price theme-color">$ 52.00</h6>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-0">
                <div className="offer-product">
                  <a href="product-left-thumbnail.html" className="offer-image">
                    <img
                      src="../assets/images/vegetable/product/26.png"
                      className="blur-up lazyload"
                      alt
                    />
                  </a>
                  <div className="offer-detail">
                    <div>
                      <a href="product-left-thumbnail.html">
                        <h6 className="name">Apple Red Premium Imported</h6>
                      </a>
                      <span>1 KG</span>
                      <h6 className="price theme-color">$ 80.00</h6>
                    </div>
                  </div>
                </div>
              </li>
            </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <HotProductSlider />
      </div>
    </>
  );
};

export default HotProductDetails;
