import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import Loader from "react-loader-spinner";
import BeatLoader from "react-spinners/BeatLoader";
import { FaMinus, FaPlus, FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { addtoCart, decrementCart } from "../redux/cart/CartSlice";
import Description from "./Home/Hotproductdetails/Description";
import HotProductSlider from "./Home/Hotproductdetails/HotProductSlider";
import RelatedProduct from "./ProductDetails/RelatedProduct";
import { IoIosArrowForward } from "react-icons/io";

const ProductDetails = () => {
  const { ProductTotal, CartItems, Cartloading, ProductLoading } = useSelector(
    (state) => state.Cart
  );


  const params = useParams();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const [showButton, setShowButton] = useState(false);
  const [productifo, setProductinfo] = useState([]);
  const [subCatproduct, setSubProduct] = useState([]);
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const productslugurl = params.producturl;

      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/grocery/Grocerybyslugurl/${productslugurl}`;
        const currentProduct = await axios.get(url);

        try {
          if (currentProduct.data.success === true) {
            setLoading(false);
            let productInfo = currentProduct.data.grocery;

            let hotproducts = productInfo;

            CartItems.forEach((cartItem) => {
              let itemIndex = -1;
              itemIndex = hotproducts.findIndex(
                (product) => String(product._id) === String(cartItem.ProductId)
              );
              if (itemIndex >= 0) {
                let packIndex = -1;
                packIndex = hotproducts[itemIndex].PackSizes.findIndex(
                  (option) => String(option._id) === String(cartItem.packsizeId)
                );
                if (packIndex >= 0) {
                  hotproducts[itemIndex].PackSizes[packIndex].CartQuantity =
                    cartItem.Qty;
                }
              }
            });

            setProductinfo(hotproducts);
          }
        } catch (error) { }
      };

      fetchData();

      if (!ProductLoading) {
        const currentProd = ProductTotal.find(
          (product) => product.Url === productslugurl
        );

        if (currentProd !== "") {
          try {
            const currentProduct = ProductTotal.filter(
              (product) => product.Url === productslugurl
            );

            const curSubCatProd = ProductTotal.filter(
              (product) => product.SubCatId === currentProd.SubCatId
            );

            const singlePro = curSubCatProd.filter(
              (product) => product.Single === true
            );
            const offerPro = singlePro.filter(
              (product) => product.Trending === true
            );
            setSubProduct(offerPro);
          } catch (error) { }
        }
      }
    } catch (error) { }
  }, [params.producturl, ProductLoading]);

  const addtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productifo];
      products[itemIndex].PackSizes[packIndex].CartQuantity += 1;
      setProductinfo([...products]);
    } catch (error) { }

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };

    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productifo];
      products[itemIndex].PackSizes[packIndex].CartQuantity -= 1;
      setProductinfo([...products]);
    } catch (error) { }

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  const changePrimery = (productId, packId, proindex) => {
    let product = [...productifo];
    product[proindex].PackSizes = product[proindex].PackSizes.map((pack) => {
      if (pack._id === packId) {
        pack.Pri = true;
      } else {
        pack.Pri = false;
      }

      return pack;
    });

    setProductinfo([...product]);
  };
  console.log(productifo, "productifo[0].SubCatId");

  return (
    <>
      {/* <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>{product.ItemName}</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a>
                        <i className="fa-solid fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active">Product Info</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {loading ? (
        <>
          <div className="d-flex justify-content-center loadingMain">
            <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
              Product is being fetched
            </h4>
            <BeatLoader
              color={"#36d7b7"}
              loading={loading}
              size={10}
              className="mt-2 mx-2"
            />
          </div>
        </>
      ) : (
        <>
          <section className="product-section" style={{ marginTop: "10px" }}>
            <div className="container-fluid-lg">
              {productifo.map((data, proindex) => (
                <>
                  <div key={proindex}>
                    {data.PackSizes.map((pack, imgpackIndex) =>
                      pack.Pri === true ? (
                        <>
                          <div className="row" key={imgpackIndex}>
                            <div className="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
                              <div className="row g-4 m-4">
                                <div className="col-xl-6 wow fadeInUp">
                                  <div className="product-left-box">
                                    <div className="row g-2">
                                      <div className="col-xxl-10 col-lg-12 col-md-12">
                                        <div className="product-main-2 no-arrow">
                                          <div>
                                            <div
                                              className="slider-image"
                                              style={{
                                                display: "flex",
                                                display: "-webkit-flex",
                                                justifyContent: "center",
                                                width: "100%",
                                              }}
                                            >
                                              {pack.hasOwnProperty(
                                                "ImgUrlDesk"
                                              ) ? (
                                                <>
                                                  <img
                                                    src={pack.ImgUrlDesk}
                                                    id="img-1"
                                                    data-zoom-image={
                                                      pack.ImgUrlDesk
                                                    }
                                                    className="img-fluid image_zoom_cls-0 blur-up lazyload"
                                                    key={imgpackIndex}
                                                    alt="img"
                                                    width="80%"
                                                  />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* dfghjkl from hereee */}
                                <div
                                  className="col-xl-6 wow fadeInUp
                                  only_in-desktop_description"
                                  data-wow-delay="0.1s"


                                >
                                  <div className="right-box-contain">
                                    <h6 className="discocontainer offer-top">
                                      {pack.Discount}% Off
                                    </h6>
                                    <h2 className="name">{data.ItemName}</h2>

                                    <div className="price-rating">
                                      <h3
                                        className=" price amounthcontainer"
                                        style={{
                                          color: "#000",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        ₹ {pack.SellingPrice}
                                        {pack.Mrp === pack.SellingPrice ? (
                                          <> </>
                                        ) : (
                                          <>
                                            <del>
                                              <del
                                                className="text-content"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                  color: "#ccc",
                                                }}
                                              >
                                                ₹ {pack.Mrp}
                                              </del>{" "}
                                            </del>
                                          </>
                                        )}
                                        <span className="offer theme-color">
                                          ({pack.Discount}% off)
                                        </span>
                                      </h3>
                                      <div className="product-rating custom-rate">
                                        <ul className="rating">
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStarHalfAlt
                                              style={{ color: "gold" }}
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="procuct-contain">
                                      <p>{data.KeyWords}</p>
                                    </div>

                                    <div className="product-packege">
                                      <div className="product-title">
                                        <h4>Weight</h4>
                                      </div>
                                      <ul className="select-packege">
                                        {data.PackSizes.map(
                                          (pacdata, index) => (
                                            <>
                                              <li
                                                key={index}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  changePrimery(
                                                    data._id,
                                                    pacdata._id,
                                                    proindex
                                                  )
                                                }
                                              >
                                                <a
                                                  className={
                                                    pacdata.Pri === true
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  {pacdata.PackSize}
                                                </a>
                                              </li>
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </div>

                                    <div className="note-box product-packege">
                                      {productifo[proindex].PackSizes[
                                        imgpackIndex
                                      ].CartQuantity > 0 ? (
                                        <>
                                          {" "}
                                          <div className="cart_qty qty-box product-qty">
                                            <div className="input-group">
                                              <button
                                                type="button"
                                                className="qty-left-minus"
                                                data-type="minus"
                                                data-field
                                                onClick={(e) =>
                                                  decrementtocartPress(
                                                    e,
                                                    data,
                                                    proindex,
                                                    imgpackIndex
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-minus"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                              <input
                                                className="form-control input-number qty-input"
                                                type="text"
                                                name="quantity"
                                                value={
                                                  productifo[proindex]
                                                    .PackSizes[
                                                    imgpackIndex
                                                  ].CartQuantity
                                                }
                                                contentEditable="false"
                                              />

                                              {9 >=
                                                productifo[proindex]
                                                  .PackSizes[imgpackIndex]
                                                  .CartQuantity ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                    onClick={(e) =>
                                                      addtocartPress(
                                                        e,
                                                        data,
                                                        proindex,
                                                        imgpackIndex
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="btn btn-md bg-dark cart-button text-white w-100"
                                            onClick={(e) =>
                                              addtocartPress(
                                                e,
                                                data,
                                                proindex,
                                                imgpackIndex
                                              )
                                            }
                                          >
                                            Add To Cart
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* to hereeeee */}

                                <div className="description_containerrrr only_in_mobile_version">
                                  <div className="dicount_offff">
                                    <h3>{pack.Discount}% Off</h3>
                                  </div>
                                  <div className="total_Descrription_containerrr">
                                    <div className="product-nameeeee">
                                      <h3>{data.ItemName}</h3>
                                    </div>
                                    {/* <div className="product_similar_pro">
                                      <Link to="/" className="colorrrr_boldddd">
                                        {" "}
                                        <span>See All Products</span>
                                      </Link>
                                      <span>
                                        <IoIosArrowForward />
                                      </span>
                                    </div> */}
                                    <div className="description_totallll">
                                      <div>
                                        {/* <span className="pack_quan_tyyyyyyy">
                                          450 ml
                                        </span> */}
                                        <ul className="select-packegexxxx">
                                          {data.PackSizes.map(
                                            (pacdata, index) => (
                                              <>
                                                <li
                                                  id="volot"
                                                  className="content_change_the_box"
                                                  key={index}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    changePrimery(
                                                      data._id,
                                                      pacdata._id,
                                                      proindex
                                                    )
                                                    handleClick(index)
                                                  }
                                                  }
                                                >
                                                  <a
                                                    id="color_to_changfe"
                                                    // className={
                                                    //   pacdata.Pri === true
                                                    //     ? "active"
                                                    //     : ""
                                                    // }
                                                    className={activeIndex === index ? "active" : ""}
                                                  >
                                                    {pacdata.PackSize}
                                                  </a>
                                                </li>
                                              </>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="container_to_flexxxxx">
                                        <div className="container_width_flex_cc">
                                          <div>
                                            <span className="pice_bold_Contenttt">
                                              ₹{pack.SellingPrice}
                                            </span>
                                          </div>
                                          <div>
                                            {pack.Mrp === pack.SellingPrice ? (
                                              <></>
                                            ) : (
                                              <>
                                                <span className="normal_contenttttt">
                                                  ₹ {pack.Mrp}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                          <div className="to_off_disss">
                                            <span> {pack.Discount}% Off</span>
                                          </div>
                                        </div>
                                        <div className="btn_tio_adddd">
                                          {productifo[proindex].PackSizes[
                                            imgpackIndex
                                          ].CartQuantity > 0 ? (
                                            <>
                                              <div className="for_incrementinggg">
                                                <button
                                                  className="for_decccc"
                                                  onClick={(e) =>
                                                    decrementtocartPress(
                                                      e,
                                                      data,
                                                      proindex,
                                                      imgpackIndex
                                                    )
                                                  }
                                                >
                                                  <FaMinus />
                                                </button>
                                                <span>
                                                  {
                                                    productifo[proindex]
                                                      .PackSizes[imgpackIndex]
                                                      .CartQuantity
                                                  }
                                                </span>

                                                {9 >=
                                                  productifo[proindex].PackSizes[
                                                    imgpackIndex
                                                  ].CartQuantity ? (
                                                  <>
                                                    <button
                                                      className="for_increme"
                                                      onClick={(e) =>
                                                        addtocartPress(
                                                          e,
                                                          data,
                                                          proindex,
                                                          imgpackIndex
                                                        )
                                                      }
                                                    >
                                                      <FaPlus />
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <button className="for_increme">
                                                      <FaPlus />
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="btn_inside_addd"
                                                onClick={(e) =>
                                                  addtocartPress(
                                                    e,
                                                    data,
                                                    proindex,
                                                    imgpackIndex
                                                  )
                                                }
                                              >
                                                Add
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>


                                      {/* <div className="btn_tio_adddd">
                                        <button className="btn_inside_addd">Add</button>
                                        <div className="for_incrementinggg">
                                          <button className="for_decccc">
                                            <FaMinus />
                                          </button>
                                          <span>1</span>
                                          <button className="for_increme">
                                            <FaPlus />
                                          </button>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>

                                {/* <Description /> */}
                                <div className="col-12"></div>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
                              <div className="right-sidebar-box">
                                <div className="pt-25">
                                  <div className="category-menu">
                                    <h3>Trending Products</h3>
                                    <ul className="product-list product-right-sidebar border-0 p-0">
                                      {subCatproduct.map((data, index) => (
                                        <li key={index}>
                                          {data.PackSizes.map((pack) =>
                                            pack.Pri === true ? (
                                              <>
                                                <Link
                                                  to={`/product-info/${data.Url}`}
                                                >
                                                  <div
                                                    className="offer-product"
                                                    key={pack._id}
                                                  >
                                                    <a className="offer-image">
                                                      <img
                                                        src={pack.ImgUrlDesk}
                                                        className="img-fluid blur-up lazyload"
                                                        alt="img"
                                                      />
                                                    </a>
                                                    <div className="offer-detail">
                                                      <div>
                                                        <a>
                                                          <h6 className="name">
                                                            {data.ItemName}
                                                          </h6>
                                                        </a>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "space-between",
                                                            width: "80px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color: "#000",
                                                              fontFamily:
                                                                "Poppins",
                                                            }}
                                                          >
                                                            ₹{pack.SellingPrice}
                                                          </span>
                                                          {pack.Mrp ===
                                                            pack.SellingPrice ? (
                                                            <> </>
                                                          ) : (
                                                            <>
                                                              {" "}
                                                              <h6
                                                                className="price"
                                                                style={{
                                                                  color: "#ccc",
                                                                  fontFamily:
                                                                    "Poppins",
                                                                  textDecorationLine:
                                                                    "line-through",
                                                                }}
                                                              >
                                                                ₹{pack.Mrp}
                                                              </h6>
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Link>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                {/* Banner Section */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div key={imgpackIndex}></div>
                        </>
                      )
                    )}
                  </div>
                </>
              ))}
            </div>
          </section>
          {showButton && (
            <div className="sticky-bottom-cart">
              <div className="container-fluid-lg">
                <div className="row">
                  <div className="col-12">
                    <div className="cart-content">
                      <div className="product-image">
                        <img
                          src="../assets/images/product/category/1.jpg"
                          className="img-fluid blur-up lazyload"
                          alt="img"
                        />
                        <div className="content">
                          <h5>Creamy Chocolate Cake</h5>
                          <h6>
                            ₹32.96<del className="text-danger">₹96.00</del>
                            <span>55% off</span>
                          </h6>
                        </div>
                      </div>
                      <div className="selection-section">
                        <div className="form-group mb-0">
                          <select
                            id="input-state"
                            className="form-control form-select"
                          >
                            <option selected disabled>
                              Choose Weight...
                            </option>
                            <option>1/2 KG</option>
                            <option>1 KG</option>
                            <option>1.5 KG</option>
                          </select>
                        </div>
                        <div className="cart_qty qty-box product-qty m-0">
                          <div className="input-group h-100">
                            <button
                              type="button"
                              className="qty-left-minus"
                              data-type="minus"
                              data-field
                            >
                              <i className="fa fa-minus" aria-hidden="true" />
                            </button>
                            <input
                              className="form-control input-number qty-input"
                              type="text"
                              name="quantity"
                              defaultValue={1}
                            />
                            <button
                              type="button"
                              className="qty-right-plus"
                              data-type="plus"
                              data-field
                            >
                              <i className="fa fa-plus" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="add-btn">
                        <a className="btn theme-bg-color text-white wishlist-btn">
                          <i className="fa fa-bookmark" /> Wishlist
                        </a>
                        <a className="btn theme-bg-color text-white">
                          <i className="fas fa-shopping-cart" /> Add To Cart
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {productifo.map((data, proindex) => (
        <>
          <div key={proindex}>
            {data.PackSizes.map((pack, imgpackIndex) =>
              pack.Pri === true ? (
                <RelatedProduct products={data.SubCatId} />
              ) : (
                <></>
              )
            )}
          </div>
        </>
      ))}

      {/* <HotProductSlider /> */}
      {/* <div
        style={{
          marginBottom: "20vh",
        }}
      ></div> */}
    </>
  );
};

export default ProductDetails;
