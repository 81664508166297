import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Modal, ModalHeader } from "reactstrap";
import EditAdress from "./address/EditAddress";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddAddress from "./address/AddAddress";
import {
  addressModelStatus,
  currAdress,
  editaddressModelStatus,
  noaddressUpdate,
  updateAddress,
} from "../../../redux/athentication/Athentication";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";

const Address = () => {
  const { currentAddress, address, addressmodel, editaddressmodel, clientid } =
    useSelector((store) => store.Athentication);

  const [model, setModel] = useState(false);
  const [editmodel, setEditModel] = useState(false);
  const [editableAddress, setEditableAddress] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const deleteClick = async (e, id) => {
  //     const data = await dispatch(addressDelete(id));
  //   };

  useEffect(() => {}, []);

  const editaddress = (e, data) => {
    const formData = {
      address: data,
    };
    setEditableAddress(formData);
    dispatch(editaddressModelStatus(true));
  };

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
  };

  const deleteClick = async (e, addressId) => {
    e.preventDefault();
    try {
      const formData = {
        ClientId: clientid,
        addressId: addressId,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const url = `${Baseurl}/api/v1/client/deleteclientsingleaddress`;
      const addAddress = await axios.put(url, formData, config);

      if (addAddress.data.success) {
        const lastaddress = addAddress.data.client;
        const currentAddress = lastaddress.Addresses;
        dispatch(updateAddress(currentAddress));
        if (currentAddress.length === 0) {
          dispatch(noaddressUpdate());
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        className="tab-pane fade show"
        id="pills-address"
        role="tabpanel"
        aria-labelledby="pills-address-tab"
      >
        <div className="dashboard-address">
          <Modal
            size="md"
            isOpen={addressmodel}
            toggle={() => dispatch(addressModelStatus(true))}
          >
            <ModalHeader>
              <AddAddress />
            </ModalHeader>
          </Modal>

          <Modal
            size="md"
            isOpen={editaddressmodel}
            toggle={() => dispatch(editaddressModelStatus(true))}
          >
            <ModalHeader>
              <EditAdress addressobject={editableAddress} />
            </ModalHeader>
          </Modal>

          <div className="title title-flex">
            <div>
              <h2 className="booktext">My Address Book</h2>
              <span className="title-leaf">
                <svg className="icon-width bg-gray">
                  <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                </svg>
              </span>
            </div>
            <button
              className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
              data-bs-toggle="modal"
              data-bs-target="#add-address"
              onClick={() => dispatch(addressModelStatus(true))}
            >
              <i data-feather="plus" className="me-2" /> Add New Address 
            </button>
          </div>
          {address && address.length > 0 ? (
            <>
              {" "}
              <div className="row g-sm-4 g-3">
                {address &&
                  address.map((data, index) => (
                    <div
                      className="col-xxl-4 col-xl-6 col-lg-12 col-md-6 p-1"
                      key={index}
                    >
                      <div className="address-box ">
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="jack"
                              id="flexRadioDefault2"
                              checked={currentAddress._id === data._id}
                              onChange={(e) => {
                                selectclintddress(e, data);
                              }}
                              value={data._id}
                            />
                          </div>
                          <div className="label">
                            <label>{data.Type}</label>
                          </div>
                          <div className="table-responsive address-table">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td colSpan={2}></td>
                                </tr>
                                <tr>
                                  <td>Address :</td>
                                  <td>
                                    <p>{data.HNo + "," + data.StreetDet}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pin Code :</td>
                                  <td>{data.Pincode}</td>
                                </tr>
                                <tr>
                                  <td>Phone :</td>
                                  <td>+91- {data.Number}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="button-group">
                          <button
                            className="btn btn-sm add-button w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#editProfile"
                            onClick={(e) => editaddress(e, data)}
                          >
                            <BiEditAlt data-feather="edit" />
                            Edit
                          </button>
                          <button
                            className="btn btn-sm add-button w-100"
                            onClick={(e) => deleteClick(e, data._id)}
                          >
                            <MdDeleteOutline data-feather="trash-2" />
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className="empty-state ">
                <div className="emptydexversioncon">
                  <img
                    src="../assets/images/address.jpg"
                    className="empty-stateimg"
                    alt="No Address"
                  />
                  <p style={{color:"#000",fontFamily: "Poppins",paddingTop:"7px"}}>No addresses available.</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="bottompadd"></div>
    </>
  );
};

export default Address;
